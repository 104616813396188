import { type Message } from "shared/apiClient";

export interface ReportAdjustedPanelProps {
  amendedUserMessages: Array<Message>;
  className?: string;
  onClickSubmit: () => void;
}

export function ReportAdjustedPanel({
  amendedUserMessages,
  className,
  onClickSubmit,
}: ReportAdjustedPanelProps) {
  return (
    <div className={className}>
      <div className="rounded-sm border-l-[4px] bg-white px-[20px] py-[16px]">
        <div className="text-textPrimary text-lg font-bold">
          👍 Your report has been updated
        </div>
        <div className="text-textSubdued mt-[2px] text-base">
          The updated report takes the following adjustment into account:
        </div>
        <div className="text-textPrimary mt-[8px] border-l-2 pl-[8px] text-base leading-5">
          {amendedUserMessages.at(-1)?.text}
        </div>
      </div>
      <div className="text-textPrimary mt-[12px] text-base leading-normal">
        ☝️<span className="font-bold">This is still a draft report.</span>{" "}
        <span>
          Once you’re happy with your proposal and how it’s been interpreted,
        </span>{" "}
        <span
          className="text-textLink cursor-pointer underline"
          onClick={onClickSubmit}
          role="button"
          tabIndex={0}
        >
          submit it for review
        </span>
        .
      </div>
    </div>
  );
}
