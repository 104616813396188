import { useState, type FormEventHandler } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSetAtom } from "jotai";

import { usePasswordResetConfirm } from "shared/apiHooks";
import { AuthFlow, authFlowSelectedVar } from "@/variables/globalVar";

import { ResetPassword } from "@/components/ResetPassword";

export function ResetPasswordPage() {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token") || "";
  const uid = searchParams.get("uid") || "";

  const setAuthFlowSelectedVar = useSetAtom(authFlowSelectedVar);

  const [errorMsg, setErrorMsg] = useState<string>("");

  const { isPending: isLoading, mutate: passwordResetConfirm } =
    usePasswordResetConfirm({
      onError(error) {
        console.error(
          "<ResetPasswordPage> usePasswordResetConfirm() onError()",
          error,
        );
      },
      onSuccess() {
        console.log(
          "<ResetPasswordPage> usePasswordResetConfirm() onSuccess()",
        );
        setErrorMsg("");
        setAuthFlowSelectedVar(AuthFlow.Login);
        navigate("/");
      },
    });

  const handleClickSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    setErrorMsg("");

    if (token && uid) {
      const formData = new FormData(event.currentTarget);
      const password = formData.get("password") as string;
      const passwordConfirm = formData.get("passwordConfirm") as string;

      if (password !== passwordConfirm) {
        setErrorMsg("Passwords must match");
        return;
      }

      if (password === "" || passwordConfirm === "") {
        setErrorMsg("Passwords must not be blank");
        return;
      }

      passwordResetConfirm({
        body: {
          new_password1: password,
          new_password2: passwordConfirm,
          token,
          uid,
        },
      });
    }
  };

  return (
    <div className="bg-interfaceWhite flex h-full w-full flex-col">
      <div className="flex h-full w-full flex-col items-center justify-center">
        <div className=" w-full px-5 md:max-w-[360px]">
          <ResetPassword
            errorMsg={errorMsg}
            isLoading={isLoading}
            onSubmit={handleClickSubmit}
          />
        </div>
      </div>
    </div>
  );
}
