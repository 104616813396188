import { atom } from "jotai";
import { atomWithImmer } from "jotai-immer";

import {
  type Account,
  type Conversation,
  type EndUserPanel,
  type Message,
} from "shared/apiClient";
import { FlowType } from "shared/types";
import { type Noticeboard } from "shared/lib";

import { ShowReportState } from "@/types/reports";

export enum Sections {
  Address,
  Loading,
}

export const wizardNavigatorVar = atom<Sections>(Sections.Address);

export const chatLockVar = atom<boolean>(false);

export enum Tabs {
  Chat = "chat",
  Lot = "lot",
  Proposal = "proposal",
  Permissibility = "permissibility",
  LotAndProposal = "lotAndProposal",
}

export const tabSelectedVar = atom<Tabs>(Tabs.Lot);

export const tabAnimationVar = atom<boolean>(false);

export type TabNotifications = {
  lot: boolean;
  proposal: boolean;
  permissibility: boolean;
  chat: boolean;
};

export const tabNotificationsVar = atom<TabNotifications>({
  lot: false,
  proposal: false,
  permissibility: false,
  chat: false,
});

export enum Pages {
  Auth = "Auth",
  Address = "Address",
  App = "App",
}

export const applicationPageVar = atom<Pages>(Pages.Address);

//InputIsLoading
export const isLoadingVar = atom<boolean>(false);

export enum AuthFlow {
  ForgotPassword = "ForgotPassword",
  Login = "Login",
  Signup = "Signup",
}

export const authFlowSelectedVar = atom<AuthFlow>(AuthFlow.Signup);

export const isAuthenticatedVar = atom<boolean>(false);

export const showReportVar = atom<ShowReportState>({
  visible: false,
  conversationId: "",
});

export const expertPanelVar = atom<EndUserPanel | null>(null);

export const panelFlowTypeVar = atom<FlowType | undefined>();

//-----------------------------------------------------------------------------

export const accountVar = atomWithImmer<Account | null>(null);
export const conversationOverrideVar = atomWithImmer<Conversation | null>(null);
export const messagesVar = atom<Array<Message>>([]);
export const noticeboardDataVar = atomWithImmer<Noticeboard>({});
