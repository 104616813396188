import { useAtom } from "jotai";
import { Loader2 } from "lucide-react";

import { createMDWrapper } from "shared/lib";
import { noticeboardDataVar } from "@/variables/globalVar";

import { useAccount, useIsProjectDetailsStale } from "@/hooks";
import { ConversationPhase } from "shared/types";

import { Skeleton } from "shared/components";

export function ProposalPanel() {
  const [noticeboardData] = useAtom(noticeboardDataVar);

  const { conversation } = useAccount();
  const isProjectDetailsStale = useIsProjectDetailsStale();

  const shouldShowLoadingIndicator =
    [
      ConversationPhase.ready_for_assessment,
      ConversationPhase.report_ready,
    ].includes(conversation?.phase as any) && isProjectDetailsStale;

  function getFactsAndQuestions(): string {
    return noticeboardData?.project_details?.raw_output || "";
  }

  function getProposedLandUse(): string {
    return noticeboardData.land_use_terms?.parsed_output
      ?.proposed_land_use_term;
  }

  function getExistingLandUse(): string {
    return noticeboardData?.land_use_terms?.parsed_output
      ?.existing_land_use_term;
  }

  function getChangeOfLandUse(): string {
    let returnStr: string = "🤔 Unsure";
    if (
      noticeboardData.land_use_terms?.parsed_output?.proposed_land_use_term &&
      noticeboardData.land_use_terms?.parsed_output?.existing_land_use_term
    ) {
      if (getProposedLandUse() === "N/A") {
        returnStr = returnStr = "❌ No";
      } else {
        returnStr = "✅ Yes";
      }
    }
    return returnStr;
  }

  function getIsPublicUse(): string {
    let returnStr: string = "🤔 Unsure";
    if (noticeboardData.development_type?.parsed_output?.public_use) {
      if (noticeboardData.development_type.parsed_output.public_use === "Yes") {
        returnStr = "✅ Yes";
      } else if (
        noticeboardData.development_type.parsed_output.public_use === "No"
      ) {
        returnStr = "❌ No";
      }
    }
    return returnStr;
  }

  function getBuildingAndWorks(): string {
    let returnStr: string = "🤔 Unsure";
    if (
      noticeboardData.development_type?.parsed_output?.buildings_and_works !=
      null
    ) {
      if (
        noticeboardData.development_type.parsed_output.buildings_and_works ===
        "Yes"
      ) {
        returnStr = "✅ Yes";
      } else if (
        noticeboardData.development_type.parsed_output.buildings_and_works ===
        "No"
      ) {
        returnStr = "❌ No";
      }
    }
    return returnStr;
  }

  return (
    <div className="flex w-full flex-col justify-start align-top">
      <h1 className="text-textPrimary mb-1 text-start text-[28px] font-semibold leading-8">
        Proposal
      </h1>

      {noticeboardData.project_details ? (
        <div className="border-interactiveBorder bg-interfaceWhite mt-2 rounded border">
          <div className="border-interactiveBorder border-b px-5 py-4 md:px-10 md:py-8 ">
            {!isProjectDetailsStale ? (
              <>
                <h2 className="text-textPrimary mb-4 text-start text-2xl font-semibold leading-7">
                  Projects
                </h2>

                {createMDWrapper(getFactsAndQuestions())}
              </>
            ) : null}

            {isProjectDetailsStale ? (
              <div className="flex flex-col gap-3">
                <div className="flex gap-1">
                  <Loader2 className="h-5 w-5 animate-spin" />
                  <h2 className="text-bodymd text-textPrimary">
                    Revising proposal summary
                  </h2>
                </div>
                <div className="flex flex-col gap-2">
                  <Skeleton className="h-6" />
                  <Skeleton className="h-6" />
                </div>
              </div>
            ) : null}
          </div>

          <div className="flex flex-col justify-center px-5 pb-4 pt-8 md:flex-row md:px-10">
            <div className="mb-5 w-full md:mb-0">
              <h2 className="text-textPrimary text-lg font-semibold leading-[22px]">
                Existing land use
              </h2>
              <p className="text-textSubdued mb-2 text-xs leading-[14px] md:mb-4">
                How is the lot currently being used?
              </p>

              {!shouldShowLoadingIndicator ? (
                <>
                  {getExistingLandUse() === "" ? (
                    <p className="text-textPrimary text-base leading-5">
                      🤔 Unsure
                    </p>
                  ) : (
                    <div>
                      <label className="w-min text-nowrap rounded-xl border-[1px] border-[#D2B7FF] bg-[#F8F3FF] px-2 py-0.5 text-sm font-medium leading-4 text-[#3A0075]">
                        {getExistingLandUse()}
                      </label>
                    </div>
                  )}
                </>
              ) : null}

              {shouldShowLoadingIndicator ? (
                <Skeleton className="h-6 md:mr-6" />
              ) : null}
            </div>

            <div className="border-interfaceBorder w-full md:border-l md:pl-6">
              <h2 className="text-textPrimary mb-0.5 text-lg font-semibold leading-[22px]">
                Proposed land use
              </h2>
              <p className="text-textSubdued mb-2 text-xs leading-[14px] md:mb-4">
                How are you planning to use the lot?
              </p>

              {!shouldShowLoadingIndicator ? (
                <>
                  {getProposedLandUse() === "" ||
                  getProposedLandUse() === "N/A" ? (
                    <div>
                      <p className="text-textPrimary text-base leading-5">
                        {getProposedLandUse() === "N/A"
                          ? "No change"
                          : "🤔 Unsure"}
                      </p>
                    </div>
                  ) : (
                    <div>
                      <label className="w-min text-nowrap rounded-xl border-[1px] border-[#D2B7FF] bg-[#F8F3FF] px-2 py-0.5 text-sm font-medium leading-4 text-[#3A0075]">
                        {getProposedLandUse()}
                      </label>
                    </div>
                  )}
                </>
              ) : null}

              {shouldShowLoadingIndicator ? <Skeleton className="h-6" /> : null}
            </div>
          </div>
          <p className="text-textSubdued px-5 text-xs leading-[14px] md:px-10">
            The existing and proposed land uses are determined by the way you’ve
            described your lot and proposal. Land use terms are defined in the
            planning scheme.
          </p>

          <div className="px-5 py-4 pt-6 md:px-10 md:pb-6">
            <div className="flex w-full flex-row items-center">
              <div className="border-interfaceBorder text-textPrimary w-full justify-start border-r py-2 pr-3 text-start text-base font-semibold leading-5 md:w-[360px] md:py-3">
                <h2>Impact to land use</h2>
                <p className="text-textSubdued text-xs font-normal leading-[14px]">
                  Is the lot being used in a new way?
                </p>
              </div>
              <div className="text-textPrimary w-[100px] px-3 py-2 text-start text-base font-normal leading-5 md:w-full md:p-3">
                {!shouldShowLoadingIndicator ? getChangeOfLandUse() : null}

                {shouldShowLoadingIndicator ? (
                  <Skeleton className="h-6" />
                ) : null}
              </div>
            </div>
            <div className="border-interfaceBorder flex w-full flex-row items-center border-t">
              <div className="border-interfaceBorder text-textPrimary w-full border-r py-2 pr-3 text-start text-base font-semibold leading-5 md:w-[360px] md:py-3">
                <h2>Public use</h2>
                <p className="text-textSubdued text-xs font-normal leading-[14px]">
                  Do the plans involve public use of the land?
                </p>
              </div>
              <div className="text-textPrimary w-[100px] px-3 py-2 text-start text-base font-normal leading-5 md:w-full md:p-3">
                {!shouldShowLoadingIndicator ? getIsPublicUse() : null}

                {shouldShowLoadingIndicator ? (
                  <Skeleton className="h-6" />
                ) : null}
              </div>
            </div>
            <div className="border-interfaceBorder flex w-full flex-row items-center border-t">
              <div className="border-interfaceBorder text-textPrimary w-full border-r py-2 pr-3 text-start text-base font-semibold leading-5 md:w-[360px] md:py-3">
                <h2>Building and works</h2>
                <p className="text-textSubdued text-xs font-normal leading-[14px]">
                  Do the plans involve activity like construction or earthworks?
                </p>
              </div>
              <div className="text-textPrimary w-[100px] px-3 py-2 text-start text-base font-normal leading-5 md:w-full md:p-3">
                {!shouldShowLoadingIndicator ? getBuildingAndWorks() : null}

                {shouldShowLoadingIndicator ? (
                  <Skeleton className="h-6" />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <p className="text-textSubdued mb-10 text-base leading-5">
            Based on your responses, we’ve described your proposal below. If
            there’s anything you see that’s inaccurate, or any extra details you
            think we should know, enter it in the chat.
          </p>

          <p className="border-interactiveBorder bg-interfaceWhite text-textPrimary rounded border px-5 py-4 text-lg leading-[22px] md:px-10 md:py-8">
            🤔 I don’t have enough information to describe your plans yet. After
            you answer more questions in the chat, your plans will begin to take
            shape here.
          </p>
        </>
      )}
    </div>
  );
}
