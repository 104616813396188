import { useAtomValue } from "jotai";

import { Tabs, tabSelectedVar } from "@/variables/globalVar";

import { WebTabs } from "@/components/WebTabs";
import { LotContainer } from "@/components/lot";
import { ProposalContainer } from "@/components/proposal";
import { PermissibilityContainer } from "./PermissibilityContainer";

export function ContentContainer() {
  const tabSelected = useAtomValue(tabSelectedVar);

  return (
    <div className="border-interactiveBorder flex h-full w-full grow flex-col items-start border-l">
      <WebTabs />
      {tabSelected === Tabs.Lot && <LotContainer />}
      {tabSelected === Tabs.Proposal && <ProposalContainer />}
      {tabSelected === Tabs.Permissibility && <PermissibilityContainer />}
    </div>
  );
}
