import { lazy, Suspense, useEffect, useMemo, useState } from "react";
import { useAtomValue } from "jotai";
import { Check, Edit } from "lucide-react";

import { noticeboardDataVar } from "@/variables/globalVar";

import { useCreateConversationMessage, useSaveReport } from "shared/apiHooks";
import { useReiterationExpert } from "shared/hooks";
import { useAccount } from "@/hooks";

import { Button } from "shared/components";
import { MarkAsDoneDialog } from "./MarkAsDoneDialog";
import { ReportDoneDialog } from "./ReportDoneDialog";
import { EventTypesEnum } from "@/types/events";
import { ConversationPhase } from "shared/types";

const AdjustProposalDialog = lazy(
  () => import("@/components/adjustProposal/AdjustProposalDialog"),
);

export function ManageReport() {
  const [adjustProposalDialogOpen, setAdjustProposalDialogOpen] =
    useState(false);
  const [saveReportDialogOpen, setSaveReportDialogOpen] = useState(false);
  const [reportDoneDialogOpen, setReportDoneDialogOpen] = useState(false);

  const { account, conversation } = useAccount();
  const noticeboardData = useAtomValue(noticeboardDataVar);
  const reiteration = useReiterationExpert(noticeboardData);

  const filteredReiteration = useMemo(() => {
    return reiteration?.replace(/^<.*>/, "").replace(/^<\/.*>/, "");
  }, [reiteration]);

  const userEmail = account?.email;

  const {
    mutate: createMessage,
    isError: isCreateMessageError,
    isPending: isCreateMessagePending,
  } = useCreateConversationMessage({
    onError: (error) => {
      console.error(
        "<ManageReport> useCreateConversationMessage() onError()",
        error,
      );
      setAdjustProposalDialogOpen(false);
    },
    onSuccess: () => {
      setAdjustProposalDialogOpen(false);
    },
  });

  const {
    mutate: saveReport,
    isError: isSaveReportError,
    isPending: isSaveReportPending,
  } = useSaveReport({
    onError: (error) => {
      console.error(
        "<ManageReport> useCreateConversationMessage() onError()",
        error,
      );
      setSaveReportDialogOpen(false);
    },
    onSuccess: () => {
      setSaveReportDialogOpen(false);
      setReportDoneDialogOpen(true);
    },
  });

  const handleClickAdjustProposal = () => {
    setAdjustProposalDialogOpen(true);
  };

  const handleSubmitAdjustProposal = (text: string) => {
    createMessage({
      body: {
        text,
        message_type: "AMENDMENT",
      },
      path: {
        application_id: conversation?.application || "",
        conversation_id: conversation?.id || "",
      },
    });
  };

  const handleClickMarkAsDone = () => {
    setSaveReportDialogOpen(true);
  };

  const handleSubmitMarkAsDone = () => {
    saveReport({
      path: { conversation_id: conversation?.id || "" },
    });
  };

  useEffect(() => {
    const handleAdjustPlansEvent: EventListener = (_event) => {
      setAdjustProposalDialogOpen(true);
    };
    document.addEventListener(
      EventTypesEnum.AdjustPlans,
      handleAdjustPlansEvent,
    );

    const handleSaveReportEvent: EventListener = (_event) => {
      setSaveReportDialogOpen(true);
    };
    document.addEventListener(EventTypesEnum.SaveReport, handleSaveReportEvent);

    return () => {
      document.removeEventListener(
        EventTypesEnum.AdjustPlans,
        handleAdjustPlansEvent,
      );
      document.removeEventListener(
        EventTypesEnum.SaveReport,
        handleSaveReportEvent,
      );
    };
  }, []);

  return (
    <>
      {conversation?.phase === ConversationPhase.report_ready ? (
        <div className="border-interactiveBorder bg-interfaceWhite text-textPrimary flex flex-wrap rounded-lg border">
          <div className="flex-1 p-6">
            <h2 className="text-headingmd mb-1 font-semibold">
              Need to make adjustments?
            </h2>
            <p className="text-bodymd mb-3">
              All good! Tell us what you’d like to adjust and we’ll generate an
              updated analysis of your proposal.
            </p>
            <Button
              className="gap-2"
              onClick={handleClickAdjustProposal}
              variant="outline"
            >
              <Edit className="h-4 w-4" /> Adjust proposal
            </Button>
          </div>
          <div className="border-interactiveBorder w-[100%] border-t md:w-auto md:border-l md:border-t-0" />

          <div className="flex-1 p-6">
            <h2 className="text-headingmd mb-1 font-semibold">All done?</h2>
            <p className="text-bodymd mb-3">
              Mark your proposal as done and we’ll send a copy of our analysis
              to <b>{userEmail}</b> after it’s been processed.
            </p>
            <Button
              className="gap-2"
              onClick={handleClickMarkAsDone}
              variant="outline"
            >
              <Check className="h-4 w-4" /> Mark proposal as done
            </Button>
          </div>
        </div>
      ) : null}
      <Suspense>
        <AdjustProposalDialog
          content={filteredReiteration}
          onSubmit={handleSubmitAdjustProposal}
          open={adjustProposalDialogOpen}
          onOpenChange={(open) => setAdjustProposalDialogOpen(open)}
          submitting={isCreateMessagePending}
        />
      </Suspense>
      <MarkAsDoneDialog
        email={userEmail || ""}
        open={saveReportDialogOpen}
        onOpenChange={setSaveReportDialogOpen}
        onSubmit={handleSubmitMarkAsDone}
        submitting={isSaveReportPending}
      />
      <ReportDoneDialog
        open={reportDoneDialogOpen}
        onOpenChange={setReportDoneDialogOpen}
      />
    </>
  );
}
