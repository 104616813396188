import { useAtomValue } from "jotai";

import { AccountType } from "shared/types";
import { sortByDate } from "@/lib/utils";
import { messagesVar } from "@/variables/globalVar";
import { useMemo } from "react";

export type UseLatestMessageOptions = {
  senderAccountType?: AccountType;
};

export function useLatestMessage(options?: UseLatestMessageOptions) {
  const { senderAccountType } = options || {};

  const messages = useAtomValue(messagesVar);

  return useMemo(() => {
    return messages
      .filter((message) => {
        if (senderAccountType) {
          return message.sender_account_type === senderAccountType;
        }
        return true;
      })
      .sort(sortByDate)
      .pop();
  }, [messages, senderAccountType]);
}
