import { useState } from "react";

import { useCouncilLocalisation } from "shared/hooks";
import { useAccount } from "@/hooks";

import { Button, Checkbox } from "shared/components";

export interface ConfirmReportProps {
  className?: string;
  setShowReport: (visible: boolean) => void;
}

export function ConfirmReport({ className, setShowReport }: ConfirmReportProps) {
  const { conversation } = useAccount();
  const { getLocalisationEntry } = useCouncilLocalisation(conversation);

  const [checked, setChecked] = useState(false);
  const [checkedTwo, setCheckedTwo] = useState(false);

  function onShowReport() {
    if (checked && checkedTwo) {
      setShowReport(true);
    }
  }

  return (
    <div className={className} data-testid="confirm-report">
      <div className="border-interactiveBorder content-start justify-start rounded-lg border-[1px] bg-white px-10 py-8">
        <h2 className="text-textPrimary mb-2 text-2xl font-semibold leading-7">
          ✅ Your report is almost ready!
        </h2>
        <p className="text-textSubdued mb-6 text-lg leading-[22px]">
          The report will tell you whether it’s likely your proposal requires a
          planning permit or not, as well as applicable planning requirements.
        </p>

        <p className="text-textPrimary mb-2 text-lg font-semibold leading-[22px]">
          The report is a guide, not an official planning decision
        </p>

        <div className="mb-4 inline-flex">
          <div className="">
            <Checkbox
              className="border-interactiveBorder rounded"
              data-testid="confirm-report-checkbox"
              id="preliminary-information-only"
              onCheckedChange={(checked) => setChecked(!!checked)}
              name="preliminary-information-only"
              role="checkbox"
            />
          </div>
          <div className="ml-3">
            <label
              className="text-textPrimary text-lg font-medium leading-[22px]"
              htmlFor="preliminary-information-only"
            >
              I understand that the report I will be shown is preliminary
              information. It is a guide and is not an official planning
              decision. It is generated using my responses and the entered
              address.
            </label>
          </div>
        </div>

        <div
          className="bg-border mb-4 h-px w-full"
          style={{ height: "1px", backgroundColor: "#E3E2E1" }}
        />

        <div className="mb-4 inline-flex">
          <div className="">
            <Checkbox
              className="border-interactiveBorder rounded"
              data-testid="confirm-report-checkbox"
              id="reliance-on-report"
              onCheckedChange={(checked) => setCheckedTwo(!!checked)}
              name="reliance-on-report"
              role="checkbox"
            />
          </div>
          <div className="ml-3">
            <label
              className="text-textPrimary text-lg font-medium leading-[22px]"
              htmlFor="reliance-on-report"
            >
              I agree that {getLocalisationEntry("councilName")} and MyLot will
              not be responsible for any loss arising from use of or reliance on
              this report.
            </label>
          </div>
        </div>

        <div className="flex flex-row-reverse">
          <Button
            disabled={!checked || !checkedTwo}
            className="bg-actionDefault text-textWhite disabled:text-textDisabled mt-6 rounded text-lg font-semibold leading-[22px] disabled:border disabled:border-[#CCCCCB] disabled:bg-[#F1F0ED]"
            onClick={() => onShowReport()}
          >
            Generate report
          </Button>
        </div>
      </div>
    </div>
  );
}
