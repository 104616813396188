import { useEffect, useRef, useState } from "react";
import { useAtomValue } from "jotai";
import Textarea from "react-textarea-autosize";
import { Loader2, RotateCcw, SendHorizonal } from "lucide-react";

import { type Conversation } from "shared/apiClient";
import { ConversationPhase } from "shared/types";
import { showReportVar } from "@/variables/globalVar";

import { Dialog, DialogTrigger } from "shared/components";
import { RestartDialog } from "@/components/restart";
import { PromptFormLoadingMessage } from "./PromptFormLoadingMessage";

export interface PromptProps {
  conversation?: Conversation;
  isChatLocked: boolean;
  isLoading: boolean;
  onSubmit: (value: string) => Promise<void>;
}

export function PromptForm({
  conversation,
  isLoading,
  isChatLocked,
  onSubmit,
}: PromptProps) {
  const [input, setInput] = useState("");

  const showReport = useAtomValue(showReportVar);

  const [restartDialogOpen, setRestartDialogOpen] = useState(false);

  const inputRef = useRef<HTMLTextAreaElement>(null);

  // const {
  //   transcript,
  //   interimTranscript,
  //   finalTranscript,
  //   resetTranscript,
  //   listening,
  // } = useSpeechRecognition();

  // useEffect(() => {
  //   if (inputRef.current) {
  //     inputRef.current.focus();
  //   }

  //   if (finalTranscript !== "") {
  //     onMicrophoneStop();
  //   }

  //   if (interimTranscript !== "") {
  //     setInput(interimTranscript);
  //   }
  // }, [interimTranscript, finalTranscript]);

  //Microphone functions
  // function onMicrophoneStart() {
  //   resetTranscript();
  //   setInput("");
  //   setMicActive(true);
  //   SpeechRecognition.startListening({
  //     continuous: false,
  //     language: "en-AU",
  //   });
  // }

  // function onMicrophoneStop() {
  //   SpeechRecognition.stopListening();
  //   setMicActive(false);
  // }

  async function handleInputSubmit() {
    if (!input?.trim()) {
      return;
    }
    setInput("");
    await onSubmit(input);
  }

  useEffect(() => {
    if (!isLoading) inputRef.current?.focus();
  }, [isLoading]);

  const inputPlaceholder = () => {
    if (isChatLocked) {
      return (
        <div className="text-textSubdued flex w-full grow flex-row flex-wrap items-center border-0 px-6 text-lg leading-[22px]">
          <span className="whitespace-nowrap">Conversation ended.</span>
          <Dialog open={restartDialogOpen} onOpenChange={setRestartDialogOpen}>
            <DialogTrigger className="text-textLink mr-1 flex flex-row items-center underline">
              <RotateCcw className="ml-1 mr-0.5 h-[16px] w-[16px]" />
              Restart
            </DialogTrigger>
            <RestartDialog
              conversationPhase={
                (conversation?.phase || "") as ConversationPhase
              }
              onOpenChange={setRestartDialogOpen}
            />
          </Dialog>
          <span className="whitespace-nowrap">
            to discuss another proposal.
          </span>
        </div>
      );
    }

    if (isLoading) {
      return (
        <div className="flex w-full grow flex-row border-0 px-3">
          <PromptFormLoadingMessage />
        </div>
      );
    }
  };

  return (
    <div
      className={`${isLoading || isChatLocked ? "bg-interfaceBgLight" : "bg-interfaceWhite"} border-interactiveBorder flex flex-row items-center gap-4 border-t py-3 pl-3 pr-4`}
    >
      <div className="flex w-full flex-row border-0">
        {!isLoading && !isChatLocked ? (
          <Textarea
            aria-label="Enter response"
            autoFocus
            className="text-textPrimary placeholder:text-textSubdued outline-textLink/75 flex-grow resize-none bg-transparent py-[9px] pl-3 text-lg leading-[22px]"
            maxRows={4}
            minRows={1}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(value) => {
              if (value.code === "Enter" && input !== "") {
                value.preventDefault();
                handleInputSubmit();
              }
            }}
            placeholder="Enter your response"
            ref={inputRef}
            rows={1}
            spellCheck={false}
            tabIndex={0}
            value={input}
          />
        ) : (
          inputPlaceholder()
        )}
        {/* {!micActive ? (
          <button onClick={onMicrophoneStart} disabled={isLoading}>
            <Mic
              className={`mr-4 h-[24px] w-[24px] ${isLoading ? "text-textDisabled" : "text-textPrimary"}`}
            />
          </button>
        ) : (
          <button onClick={onMicrophoneStart} disabled={true}>
            <AudioLines className="mr-4 h-[24px] w-[24px] animate-pulse text-red-500" />
          </button>
        )} */}
      </div>

      <button
        aria-label="Submit response"
        className="outline-textLink/75 p-3"
        disabled={isLoading || isChatLocked}
        onClick={handleInputSubmit}
        tabIndex={0}
      >
        {isLoading ? (
          <Loader2
            className={`text-textSubdued h-[18px] w-[18px] animate-spin`}
          />
        ) : (
          <SendHorizonal
            className={`${isChatLocked ? "text-textSubdued" : ""} text-textSubdued h-[18px] w-[18px]`}
          />
        )}
      </button>
    </div>
  );
}
