import { useMemo, useRef } from "react";
import { useAtomValue } from "jotai";
import { X } from "lucide-react";

import { noticeboardDataVar } from "@/variables/globalVar";
import { createMDWrapper } from "shared/lib";

import {
  Button,
  Dialog,
  DialogContent,
  DialogClose,
  DialogTitle,
  Textarea,
  Label,
} from "shared/components";

export interface AdjustPlansDialogProps {
  onClickAdjustPlan: (adjustments: string) => void;
  onOpenChange: (open: boolean) => void;
  open: boolean;
}

export function AdjustPlansDialog({
  onClickAdjustPlan,
  onOpenChange,
  open,
}: AdjustPlansDialogProps) {
  const noticeboardData = useAtomValue(noticeboardDataVar);

  const adjustmentsRef = useRef<HTMLTextAreaElement | null>(null);

  const missingInfo = useMemo(() => {
    if (noticeboardData?.missing_info?.raw_output) {
      return noticeboardData.missing_info.raw_output;
    }
    return "";
  }, [noticeboardData]);

  function getFactsAndQuestions() {
    return noticeboardData?.project_details?.raw_output || "";
  }

  const handleClickAdjustPlan = () => {
    if (adjustmentsRef.current) {
      onClickAdjustPlan(adjustmentsRef.current.value);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent
        aria-describedby={undefined}
        className="max-w-[680px] gap-0 rounded-none border-0 p-0 px-5"
        hideCloseButton
      >
        <DialogTitle className="sr-only">Adjust your proposal</DialogTitle>
        <div className="bg-interfaceWhite flex max-h-[90vh] flex-col rounded-3xl pt-6 md:pt-6">
          <DialogClose
            aria-label="Close"
            className="mr-5 self-end md:mr-6"
            role="button"
          >
            <X className="text-textPrimary h-6 w-6" />
          </DialogClose>
          <div className="overflow-y-auto rounded-b-[20px]">
            <div className="bg-interfaceWhite flex flex-col gap-6 px-5 pb-6 md:gap-8 md:px-[60px] md:pb-[30px]">
              <h2 className="text-textPrimary text-[28px] font-semibold leading-[1.2]">
                Adjust your proposal
              </h2>
              <p className="text-textPrimary text-lg leading-[1.2222]">
                Add further information or adjust your project in the section
                below. We can then re-generate your report for an updated
                result.
              </p>
              <p className="border-interactiveBorderDefault border-radius-[4px] max-h-[150px] overflow-y-auto border p-4 md:max-h-[328px] md:p-5">
                {createMDWrapper(
                  missingInfo ? missingInfo : getFactsAndQuestions(),
                )}
              </p>
            </div>
            <div className="bg-interfaceBgDefault flex flex-col gap-[12px] rounded-b-[20px] px-[20px] py-[24px] md:px-[60px] md:py-[30px]">
              <div className="flex flex-col gap-1">
                <Label htmlFor="amendments-textarea">Adjustments</Label>
                <Textarea
                  className="h-[100px] text-lg leading-[1.375]"
                  id="amendments-textarea"
                  placeholder="Enter adjustments"
                  ref={adjustmentsRef}
                />
              </div>
              <div className="flex justify-end gap-[12px]">
                <Button
                  className="border-blue-600 text-blue-600"
                  onClick={() => onOpenChange(false)}
                  variant="outline"
                >
                  Cancel
                </Button>
                <Button
                  className="bg-blue-600 text-white"
                  onClick={handleClickAdjustPlan}
                  type="submit"
                >
                  Adjust proposal
                </Button>
              </div>
              <p className="text-textPrimary self-end text-sm">
                ☝️ Adjusting your proposal will update the report, which takes
                about 90 seconds.
              </p>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
