import { useEffect } from "react";

import { useTracking } from "@/hooks/useTracking";

import { LotPanel } from "./LotPanel";

export function LotContainer() {
  const { stateChange, readyState } = useTracking("<LotContainer>");

  useEffect(() => {
    if (readyState) {
      stateChange("/lot");
    }
  }, [readyState, stateChange]);

  return (
    <div className="bg-interfaceBgDefault flex h-full w-full flex-col justify-start overflow-y-auto align-top">
      <div className="bg-interfaceBgDefault md:px-15 flex w-full flex-col justify-start p-6 align-top md:py-10">
        <LotPanel showDisclaimer />
      </div>
    </div>
  );
}
