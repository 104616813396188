import { useMemo, useState } from "react";
import { Loader2 } from "lucide-react";

import { type Message } from "shared/apiClient";
import type { QuestionData } from "@/components/wizard/lib";
import { cn } from "shared/lib";

import { createMessageEvent, useChannel } from "shared/channel";
import { useMediaQuery } from "shared/hooks";
import { useAccount } from "@/hooks";

import { Button, Slider } from "shared/components";
import { LoadingOverlay } from "@/components/wizard/widgets/LoadingOverlay";
import { Background } from "./Background";
import { Person } from "./Person";
import { TreeLarge } from "./TreeLarge";
import { TreeMedium } from "./TreeMedium";
import { TreeSmall } from "./TreeSmall";
import { LargeIcon } from "./LargeIcon";
import { MediumIcon } from "./MediumIcon";
import { SmallIcon } from "./SmallIcon";

export interface TreeSizeStepProps {
  disabled?: boolean;
  message: Message;
  showLoading?: boolean;
}

export function TreeSizeStep({
  disabled,
  message,
  showLoading,
}: TreeSizeStepProps) {
  const questionData = message.question_data as QuestionData;

  const { channel } = useChannel();

  const { conversation } = useAccount();

  const [submitting, setSubmitting] = useState(false);
  const [treeDiameter, setTreeDiameter] = useState(0.3);
  const [presetSize, setPresetSize] = useState<
    "small" | "medium" | "large" | null
  >(null);

  const shouldRenderSideBySide = useMediaQuery({
    query: "(min-width: 822px)",
  });

  const handleClickNext = () => {
    if (channel && conversation?.id) {
      setSubmitting(true);

      const text = `The tree will have a trunk diameter at breast height (DBH) of ${treeDiameter.toFixed(1)} meters.`;

      window.localStorage.setItem(
        `${message.conversation_id}.treeDiameter`,
        `${treeDiameter}`,
      );

      const messageDescriptor = createMessageEvent({
        conversation_id: conversation.id,
        text,
      });

      console.log("<TreeSizeStep> handleClickNext()", messageDescriptor);
      channel.sendMessage(messageDescriptor);
    }
  };

  const lineWidth = useMemo(() => {
    if (treeDiameter <= 0.4) {
      return treeDiameter * 35;
    }
    if (treeDiameter > 0.4 && treeDiameter <= 0.9) {
      return treeDiameter * 60;
    }
    if (treeDiameter > 0.9) {
      return treeDiameter * 70;
    }
  }, [treeDiameter]);

  const treeMarginLeft = useMemo(() => {
    if (treeDiameter === 0.1) {
      return "mr-[-15px]";
    }
    if (treeDiameter === 0.2) {
      return "mr-[-18px]";
    }
    if (treeDiameter === 0.3) {
      return "mr-[-22px]";
    }
    if (treeDiameter === 0.4) {
      return "mr-[-25px]";
    }
    if (treeDiameter === 0.5) {
      return "mr-[-10px]";
    }
    if (treeDiameter === 0.6) {
      return "mr-[-12px]";
    }
    if (treeDiameter === 0.7) {
      return "mr-[-13px]";
    }
    if (treeDiameter === 0.8) {
      return "mr-[-15px]";
    }
    if (treeDiameter === 0.9) {
      return "mr-[-18px]";
    }
    if (treeDiameter > 0.9) {
      return "";
    }
  }, [treeDiameter]);

  const treeMarginRight = useMemo(() => {
    if (treeDiameter < 0.9) {
      return "";
    }
    if (treeDiameter === 1.0) {
      return "ml-[-55px]";
    }
    if (treeDiameter === 1.1) {
      return "ml-[-58px]";
    }
    if (treeDiameter === 1.2) {
      return "ml-[-60px]";
    }
  }, [treeDiameter]);

  const treeSize = (treeDiameter + 0.2) * 550;

  const renderDiagram = () => {
    return (
      <>
        <Background className="w-full grow" />

        <div className="absolute bottom-0 left-0 right-0 top-0 z-10 flex items-end justify-start">
          <div className="grow-[2]" />
          <Person className="mb-6" style={{ transform: "scale(1.1, 1.1)" }} />
          <div className="grow-[8]" />
        </div>

        <div className="absolute bottom-0 left-0 right-0 top-0 z-10 flex flex-col items-center">
          {treeDiameter <= 0.4 && (
            <TreeSmall
              className={`absolute bottom-[19px] ${treeMarginLeft}`}
              style={{
                width: `${treeSize}px`,
                height: `${treeSize}px`,
              }}
            />
          )}
          {treeDiameter > 0.4 && treeDiameter <= 0.9 && (
            <TreeMedium
              className={`absolute bottom-[19px] ${treeMarginLeft}`}
              style={{
                width: `${treeSize}px`,
                height: `${treeSize}px`,
              }}
            />
          )}
          {treeDiameter > 0.9 && (
            <TreeLarge
              className={`absolute bottom-[19px] ${treeMarginRight}`}
              style={{
                width: `${treeSize}px`,
                height: `${treeSize}px`,
              }}
            />
          )}
        </div>

        <div className="absolute bottom-0 left-0 right-0 top-0 z-50 flex flex-col items-center">
          <div className="absolute bottom-[64px] flex flex-col items-center gap-2">
            <div className="flex items-center">
              <div className="bg-textPrimary h-2 w-[1px]" />
              <div
                className="border-textPrimary h-[1px] border-t border-dashed"
                style={{ width: `${lineWidth}px` }}
              />
              <div className="bg-textPrimary h-2 w-[1px]" />
            </div>
            <div className="relative flex h-[1px] w-[1px] justify-center">
              <p className="bg-interfaceWhite text-textPrimary absolute rounded px-1 text-base leading-5">
                {treeDiameter.toFixed(1)}m
              </p>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderDefaultButtons = () => {
    return (
      <>
        <Button
          className="data-[state=on]:bg-blueDefault data-[state=on]:border-blueDefault h-fit gap-1 px-3 py-0.5 data-[state=on]:text-white"
          data-state={presetSize === "small" ? "on" : "off"}
          disabled={submitting || showLoading || disabled}
          onClick={() => {
            setPresetSize("small");
            setTreeDiameter(0.3);
          }}
          variant="outline"
        >
          <SmallIcon className="h-6" />
          Small
        </Button>
        <Button
          className="data-[state=on]:bg-blueDefault h-fit gap-1 px-3 py-0.5 data-[state=on]:border-white data-[state=on]:text-white"
          data-state={presetSize === "medium" ? "on" : "off"}
          disabled={submitting || showLoading || disabled}
          onClick={() => {
            setPresetSize("medium");
            setTreeDiameter(0.6);
          }}
          variant="outline"
        >
          <MediumIcon className="h-6" />
          Medium
        </Button>
        <Button
          className="data-[state=on]:bg-blueDefault h-fit gap-1 px-3 py-0.5 data-[state=on]:border-white data-[state=on]:text-white"
          data-state={presetSize === "large" ? "on" : "off"}
          disabled={submitting || showLoading || disabled}
          onClick={() => {
            setPresetSize("large");
            setTreeDiameter(1);
          }}
          variant="outline"
        >
          <LargeIcon className="h-6" />
          Large
        </Button>
      </>
    );
  };

  const renderFooter = () => {
    return (
      <>
        <Button
          className="wizard-step-hide"
          data-testid="next-button"
          disabled={submitting || showLoading || disabled}
          onClick={handleClickNext}
        >
          {submitting || showLoading ? (
            <>
              <Loader2 className="mr-3 animate-spin" data-testid="submitting" />
            </>
          ) : null}
          {questionData.next_button_label || "Next"}
        </Button>
      </>
    );
  };

  return (
    <div
      className={cn(
        "wizard-step-transition flex h-full w-full justify-center gap-12",
        shouldRenderSideBySide ? "max-w-[882px] items-center" : "",
      )}
      data-testid="step-container-tree-size"
    >
      {shouldRenderSideBySide ? (
        <div className="relative z-0 flex min-h-[460px] flex-col overflow-hidden">
          {renderDiagram()}
        </div>
      ) : null}

      <div
        className={cn(
          "flex w-full flex-col gap-4",
          shouldRenderSideBySide ? "max-w-[440px]" : "",
        )}
      >
        <div className="w-full">
          <h2 className="text-textPrimary mb-2 text-[24px] font-semibold leading-[28px]">
            How large is the tree?
          </h2>
        </div>

        {!shouldRenderSideBySide ? (
          <div className="relative z-0 flex min-h-[460px] flex-col overflow-hidden">
            {renderDiagram()}
          </div>
        ) : null}

        <div
          className={cn("flex gap-2", shouldRenderSideBySide ? "w-fit" : "")}
        >
          {renderDefaultButtons()}
        </div>

        <div className="flex flex-col">
          <label className="text-textPrimary pb-2 text-base font-semibold leading-5">
            Tree diameter at breast height
          </label>
          <div className="flex items-center gap-2">
            <Slider
              defaultValue={[0.3]}
              disabled={submitting || showLoading || disabled}
              max={1.0}
              min={0.1}
              step={0.1}
              onValueChange={(value) => {
                setTreeDiameter(value[0]);
                setPresetSize(null);
              }}
              value={[treeDiameter]}
            />
            {treeDiameter < 1.0 ? (
              <div className="w-[20px]">{treeDiameter}m</div>
            ) : null}
            {treeDiameter >= 1.0 ? (
              <div className="w-[20px]">{treeDiameter}m+</div>
            ) : null}
          </div>
        </div>

        {!shouldRenderSideBySide ? <div className="flex-grow" /> : null}

        <div className="mt-3 flex justify-end pb-6 md:pb-12">
          {renderFooter()}
        </div>
      </div>

      {submitting || showLoading || disabled ? <LoadingOverlay /> : null}
    </div>
  );
}
