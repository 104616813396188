import { Check, Loader2, Pencil } from "lucide-react";

import { Button, DialogTitle, DialogFooter } from "shared/components";

export interface ReportReadyContentProps {
  email: string;
  onClickCancel: () => void;
  onClickAdjustProposal: () => void;
  onClickMarkAsDone: () => void;
  onClickSubmit: () => void;
  submitting: boolean;
}

export function ReportReadyContent({
  email,
  onClickAdjustProposal,
  onClickCancel,
  onClickMarkAsDone,
  onClickSubmit,
  submitting,
}: ReportReadyContentProps) {
  return (
    <>
      <DialogTitle>Start again?</DialogTitle>
      <div className="text-textPrimary flex flex-col gap-4">
        <p className="text-bodylg">
          If you restart, you will lose all the responses you’ve entered.
        </p>
        <div className="border-interfaceBorder bg-interfaceBgDefault flex flex-col gap-4 rounded-sm border-l-4 px-4 py-3">
          <h2 className="text-headingmd font-semibold">
            ☝ Before you restart...
          </h2>
          <div>
            <h3 className="text-headingsm font-semibold">
              Try adjusting your proposal
            </h3>
            <p className="text-bodymd mb-2">
              Your adjustments will be taken into account and a new analysis
              will be generated with a potentially different result.
            </p>
            <Button
              className="my-0 h-auto gap-2 rounded-full py-1"
              onClick={onClickAdjustProposal}
              size="sm"
              variant="outline"
            >
              <Pencil className="h-3 w-3" />
              Adjust proposal
            </Button>
          </div>
          <div>
            <h3 className="text-headingsm font-semibold">
              Mark your proposal as done
            </h3>
            <p className="text-bodymd mb-2">
              Mark your proposal as done and we’ll send a copy of our analysis
              to <b>{email}</b> after it’s been processed.
            </p>
            <Button
              className="my-0 h-auto gap-2 rounded-full py-1"
              onClick={onClickMarkAsDone}
              size="sm"
              variant="outline"
            >
              <Check className="h-3 w-3" />
              Mark proposal as done
            </Button>
          </div>
        </div>
      </div>
      <DialogFooter>
        <Button onClick={onClickCancel} variant="outline">
          Cancel
        </Button>
        <Button className="gap-2" onClick={onClickSubmit} variant="default">
          {submitting && (
            <>
              <Loader2 className="animate-spin" />{" "}
            </>
          )}
          Discard and restart
        </Button>
      </DialogFooter>
    </>
  );
}
