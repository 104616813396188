import { Loader2 } from "lucide-react";

import { Button, DialogTitle, DialogFooter } from "shared/components";

export interface ReportDoneContentProps {
  onClickCancel: () => void;
  onClickSubmit: () => void;
  submitting: boolean;
}

export function ReportDoneContent({
  onClickCancel,
  onClickSubmit,
  submitting,
}: ReportDoneContentProps) {
  return (
    <>
      <DialogTitle>Start again?</DialogTitle>
      <p className="text-bodylg text-textPrimary">
        This will start a new proposal, beginning at entering an address.
      </p>
      <DialogFooter>
        <Button onClick={onClickCancel} variant="outline">
          Cancel
        </Button>
        <Button className="gap-2" onClick={onClickSubmit} variant="default">
          {submitting && (
            <>
              <Loader2 className="animate-spin" />{" "}
            </>
          )}
          Restart
        </Button>
      </DialogFooter>
    </>
  );
}
