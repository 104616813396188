import { useState } from "react";
import { Loader2 } from "lucide-react";

import { type Message } from "shared/apiClient";
import { createMessageEvent, useChannel } from "shared/channel";
import { useAccount } from "@/hooks";

import { Button, Textarea } from "shared/components";

export interface TextMessageStepProps {
  disabled?: boolean;
  message: Message;
  showLoading?: boolean;
}

export function TextMessageStep({
  disabled,
  message,
  showLoading,
}: TextMessageStepProps) {
  const { channel } = useChannel();

  const { conversation } = useAccount();

  const [submitting, setSubmitting] = useState(false);
  const [responseText, setResponseText] = useState("");

  const handleClickNext = () => {
    if (channel && conversation?.id) {
      setSubmitting(true);

      const messageDescriptor = createMessageEvent({
        conversation_id: conversation.id,
        text: responseText,
      });
      console.log("<TextMessageStep> handleClickNext()", messageDescriptor);
      channel.sendMessage(messageDescriptor);
    }
  };

  return (
    <div className="flex h-full flex-col">
      <h2 className="text-textPrimary pb-3 text-[28px] font-semibold leading-[32px]">
        {message.text}
      </h2>

      <Textarea
        disabled={submitting || showLoading || disabled}
        onChange={(event) => setResponseText(event.target.value)}
        placeholder="Enter response"
        value={responseText}
      />

      <div className="flex-grow" />

      <div className="mt-3 flex justify-end">
        <Button
          disabled={submitting || showLoading || disabled || !responseText}
          onClick={handleClickNext}
        >
          {submitting || showLoading ? (
            <>
              <Loader2 className="mr-3 animate-spin" />
            </>
          ) : null}
          Next
        </Button>
      </div>
    </div>
  );
}
