export function MaintenancePage() {
  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <div className="bg-white p-[20px]">
        <div>
          <h1 className="mb-2 text-[32px] font-semibold leading-9 text-[#373530]">
            🔧 We're enhancing your planning experience!
          </h1>

          <p className="mb-1 text-[16px] text-[#373530]">
            🌟 Planned maintenance is underway to improve our planning tool.
          </p>

          <p className="text-[16px] text-[#373530]">
            📞 Need immediate assistance?{" "}
            <a
              className="text-textLink underline"
              href="https://www.yarraranges.vic.gov.au/Contact"
              rel="noreferrer"
              target="_blank"
            >
              Contact Yarra Ranges Council
            </a>
            .
          </p>
          <p className="mt-4 text-[16px] text-[#373530]">
            We appreciate your patience. Stay tuned for the enhanced version!
          </p>
        </div>

        <div className="mt-[24px] rounded-l-sm border-l-4 border-l-[color:#E3E2E1] bg-[#F9F8F5] py-[20px] pl-[24px] pr-[20px]">
          <h2 className="text-[24px] font-semibold leading-7 text-[#373530]">
            👉 Get in touch for a demo
          </h2>
          <p>
            Yarra Ranges Council’s self-service planning enquiry tool is powered
            by{" "}
            <a href="https://www.mylot.ai/" rel="noreferrer" target="_blank">
              myLot
            </a>
            .
          </p>
          <p className="text-[16px] text-[#373530]">
            If you’d like a demo,{" "}
            <a className="text-textLink underline" href="mailto:tim@mylot.ai">
              contact the myLot team
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
}
