import { ComponentProps } from "react";

export function TreeMedium({ className, style }: ComponentProps<"svg">) {
  return (
    <svg
      width="142"
      height="187"
      viewBox="0 0 142 187"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <g clipPath="url(#clip0_6995_5152)">
        <path
          d="M67.84 76.349C64.16 72.759 61.95 86.449 60.84 83.349C59.73 80.249 53.63 76.3491 49.5 77.0291C45.37 77.7091 48.06 70.5791 44.19 69.7691C40.32 68.9591 34.3 66.8991 35.74 61.9491C37.18 56.9991 37.35 54.2191 35.82 51.8791C34.29 49.5391 42.03 51.159 44.99 48.559C47.95 45.959 54.07 37.949 54.99 41.359C55.91 44.769 58.4 40.4591 61.19 40.6391C63.98 40.8191 60.19 47.2891 64.07 48.0991C67.95 48.9091 73.67 41.799 72.64 47.149C71.61 52.499 71.26 58.069 73.64 56.539C76.02 55.009 76.56 61.459 74.42 65.109C72.28 68.759 71.53 79.949 67.84 76.349Z"
          fill="#71956D"
        />
        <path
          d="M126.94 54.1209C121.94 52.8209 126.61 64.8609 124.13 62.8509C121.65 60.8409 114.31 60.3509 110.94 62.6809C107.57 65.0109 106.51 58.0909 102.67 59.1209C98.8299 60.1509 92.4599 61.1209 91.3299 56.4709C90.1999 51.8209 89.0099 49.4709 86.5099 48.2909C84.0099 47.1109 91.6799 45.001 93.0599 41.601C94.4399 38.201 95.9799 29.0609 98.4399 31.4309C100.9 33.8009 101.05 29.211 103.62 28.141C106.19 27.071 105.98 33.961 109.82 32.921C113.66 31.881 115.31 23.651 117 28.421C118.69 33.191 121.09 37.8609 122.43 35.6109C123.77 33.3609 127.43 38.311 127.3 42.201C127.17 46.091 131.97 55.4209 126.94 54.1209Z"
          fill="#71956D"
        />
        <path
          d="M36.6801 53.4192C32.2101 51.8292 35.4901 62.9493 33.4001 60.9493C31.3101 58.9493 24.6801 57.8792 21.4001 59.6592C18.1201 61.4392 17.7601 55.1893 14.1801 55.7693C10.6001 56.3493 4.74009 56.6593 4.10009 52.4393C3.46009 48.2193 2.56009 46.0493 0.390095 44.7493C-1.77991 43.4493 5.39009 42.2692 6.90009 39.3592C8.41009 36.4492 10.6001 28.4593 12.6401 30.7793C14.6801 33.0993 15.2001 29.0293 17.6401 28.3093C20.0801 27.5893 19.3001 33.6793 22.8801 33.0893C26.4601 32.4993 28.6401 25.3193 29.7801 29.7093C30.9201 34.0993 32.7101 38.4593 34.1201 36.5693C35.5301 34.6793 38.4301 39.3993 38.0001 42.8493C37.5701 46.2993 41.1501 55.0092 36.6801 53.4192Z"
          fill="#71956D"
        />
        <path
          d="M78.29 182.75L76.29 161.75C76.29 161.75 73.29 147.75 73.29 141.75C73.29 135.75 76.29 111.47 78.29 104.61C80.29 97.7498 88.29 82.7498 91.29 77.7498C94.29 72.7498 102.29 67.7498 109.29 67.7498V66.2998C109.29 66.2998 99.29 68.7498 98.29 67.7498C98.29 65.7498 103.29 56.7498 106.29 54.7498L104.29 53.7498L102.29 56.7498V52.7498H101.02C101.02 52.7498 98.29 58.7498 97.29 60.7498C96.29 62.7498 95.29 66.7498 91.29 70.7498C87.29 74.7498 83.29 80.7498 82.29 81.7498C81.29 82.7498 81.29 81.7498 80.29 79.7498C79.29 77.7498 76.29 66.7498 77.29 61.7498C78.5487 54.7294 81.6553 48.1711 86.29 42.7498L91.29 36.0898H87.6C87.6 36.0898 79.29 44.7498 75.29 55.7498C71.29 46.7498 69.29 44.7498 69.29 37.7498L65.29 40.7498C65.29 40.7498 68.29 43.7498 70.29 54.7498C72.29 65.7498 73.29 78.7498 71.29 87.7498C69.29 96.7498 69.29 101.75 68.29 103.75C67.29 105.75 64.29 106.98 65.29 113.36C66.29 119.74 66.69 121.61 65.09 120.7C63.49 119.79 55.29 109.7 53.29 106.7C51.29 103.7 47.29 97.6999 47.29 93.6999C47.29 89.6999 47.29 79.6999 52.29 72.6999L50.29 70.6999C49.4805 71.5969 48.8067 72.6076 48.29 73.6999C47.5285 75.327 46.8608 76.9964 46.29 78.6999C43.8236 70.6086 42.8079 62.1449 43.29 53.6999V43.6999L40.29 45.6999V59.3799C40.29 59.3799 34.29 51.6999 32.29 50.6999V54.1098C32.29 54.1098 38.29 59.6999 39.29 63.6999C40.29 67.6999 42.29 78.6999 42.29 81.6999C42.29 84.6999 44.29 89.6999 44.29 91.6999C44.29 93.6999 43.29 93.6999 41.29 90.6999L31.29 75.6999C31.29 75.6999 28.29 81.6999 30.29 83.6999C32.29 85.6999 46.29 101.7 50.29 108.7C54.29 115.7 61.29 126.7 62.29 133.7C63.29 140.7 60.29 169.7 58.29 173.7C56.29 177.7 50.79 185.25 57.79 186.25C64.79 187.25 78.29 187.75 78.29 182.75Z"
          fill="#CABEB1"
        />
        <path
          d="M106.41 61.1601C110.68 62.2301 106.41 51.1602 108.55 52.9502C110.69 54.7402 116.88 54.9501 119.66 52.7401C122.44 50.5301 123.5 56.8801 126.71 55.8201C129.92 54.7601 135.25 53.6802 136.32 57.9502C137.39 62.2202 138.46 64.3601 140.59 65.4301C142.72 66.5001 136.32 68.6302 135.25 71.8402C134.18 75.0502 133.11 83.5902 130.98 81.4502C128.85 79.3102 128.84 83.5901 126.71 84.6601C124.58 85.7301 124.57 79.3201 121.37 80.3801C118.17 81.4401 116.98 89.1702 115.43 84.7802C113.88 80.3902 111.75 76.1101 110.68 78.2501C109.61 80.3901 106.41 75.8701 106.41 72.2501C106.41 68.6301 102.14 60.0901 106.41 61.1601Z"
          fill="#89B380"
        />
        <path
          d="M36.6199 75.11C32.2899 74.32 37.2899 85.11 35.0399 83.43C32.7899 81.75 26.5899 81.95 23.9699 84.43C21.3499 86.91 19.8599 80.54 16.7299 81.82C13.5999 83.1 8.33988 84.52 6.98988 80.33C5.63988 76.14 4.43988 74.08 2.22988 73.16C0.0198819 72.24 6.27989 69.67 7.13989 66.41C7.99989 63.15 8.48988 54.54 10.7599 56.53C13.0299 58.52 12.7599 54.26 14.8099 53.05C16.8599 51.84 17.2999 58.23 20.4199 56.96C23.5399 55.69 24.2099 47.9 26.0499 52.18C27.8899 56.46 30.2999 60.58 31.2199 58.38C32.1399 56.18 35.6399 60.46 35.8899 64.07C36.1399 67.68 40.9599 75.89 36.6199 75.11Z"
          fill="#89B380"
        />
        <path
          d="M83.57 32.2506C77.57 30.8506 83.22 43.6606 80.28 41.5206C77.34 39.3806 68.63 38.8306 64.64 41.2906C60.65 43.7506 59.38 36.3906 54.83 37.4706C50.28 38.5506 42.74 39.5406 41.38 34.6206C40.02 29.7006 38.6 27.2106 35.63 25.9006C32.66 24.5906 41.76 22.4306 43.37 18.8206C44.98 15.2106 46.79 5.50059 49.72 8.03059C52.65 10.5606 52.8 5.68059 55.84 4.56059C58.88 3.44059 58.66 10.7406 63.21 9.66059C67.76 8.58059 69.68 -0.179415 71.7 4.90059C73.72 9.98058 76.58 14.9506 78.16 12.5606C79.74 10.1706 84.09 15.4506 83.96 19.5606C83.83 23.6706 89.55 33.6906 83.57 32.2506Z"
          fill="#71956D"
        />
        <path
          d="M76.5599 7.83089C81.7399 11.2509 80.8399 -2.59911 82.8899 0.430892C84.9399 3.46089 92.9999 7.0709 97.6199 6.2209C102.24 5.3709 100.91 12.6209 105.62 13.2209C110.33 13.8209 117.8 15.6209 117.38 20.6509C116.96 25.6809 117.45 28.4909 119.81 30.7609C122.17 33.0309 112.81 31.7609 110.03 34.5509C107.25 37.3409 102.19 45.6409 100.28 42.2509C98.3699 38.8609 96.5499 43.3309 93.2799 43.2509C90.0099 43.1709 92.7499 36.5309 88.0699 35.9109C83.3899 35.2909 78.5299 42.6909 78.3799 37.2809C78.2299 31.8709 77.2299 26.2809 74.9099 27.9009C72.5899 29.5209 70.2899 23.1209 71.8399 19.3409C73.3899 15.5609 71.3899 4.40089 76.5599 7.83089Z"
          fill="#89B380"
        />
        <path
          d="M21.7901 31.1598C27.4501 31.0498 19.4401 20.7398 22.5701 21.9998C25.7001 23.2598 33.5701 21.7498 36.5701 18.6098C39.5701 15.4698 42.4101 21.8298 46.2101 19.7998C50.0101 17.7698 56.5101 15.1398 58.8601 19.2798C61.2101 23.4198 63.0701 25.3398 66.0201 25.8498C68.9701 26.3598 61.37 30.3998 60.78 34.0398C60.19 37.6798 60.8401 46.8898 57.6401 45.2698C54.4401 43.6498 55.4401 48.1098 52.9901 49.8298C50.5401 51.5498 48.9901 44.8298 45.2301 46.9098C41.4701 48.9898 41.7501 57.3098 38.7701 53.1698C35.7901 49.0298 32.0701 45.1698 31.2201 47.7298C30.3701 50.2898 25.2201 46.4798 24.4101 42.7298C23.6001 38.9798 16.0901 31.2498 21.7901 31.1598Z"
          fill="#89B380"
        />
      </g>
      <defs>
        <clipPath id="clip0_6995_5152">
          <rect width="141.02" height="186.91" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
