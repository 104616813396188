import { useMemo } from "react";
import { useAtomValue } from "jotai";

import { AccountType } from "shared/types";
import { noticeboardDataVar } from "@/variables/globalVar";

import { useLatestMessage } from "@/hooks";

export function useIsProjectDetailsStale() {
  const latestUserMessage = useLatestMessage({
    senderAccountType: AccountType.USER,
  });

  const noticeboardData = useAtomValue(noticeboardDataVar);

  return useMemo(() => {
    const projectDetails = noticeboardData?.project_details;
    if (projectDetails && latestUserMessage?.message_number) {
      return (
        projectDetails.message_number !==
        parseInt(latestUserMessage.message_number)
      );
    }
    return false;
  }, [latestUserMessage, noticeboardData]);
}
