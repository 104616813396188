import { forwardRef, type ElementType, ForwardedRef } from "react";

import { cn } from "shared/lib";

interface Props {
  icon: ElementType;
  label: string;
  onSelect: () => void;
  onSelectNext?: () => void;
  onSelectPrevious?: () => void;
  selected: boolean;
  showUpdateIcon?: boolean;
  shouldAnimate?: boolean;
}

function InnerTabItem({
  icon,
  label,
  onSelect,
  onSelectNext,
  onSelectPrevious,
  selected,
  showUpdateIcon = false,
  shouldAnimate,
}: Props, ref: ForwardedRef<HTMLButtonElement>) {
  const Icon = icon;

  return (
    <button
      aria-label={`${label} tab`}
      aria-selected={selected}
      className={cn(
        "border-interfaceBorder text-textPrimary md:hover:bg-interfaceBgLight flex flex-1 cursor-pointer flex-col items-center justify-center border-r py-3 text-xs leading-[14px] last:border-r-0",
        "md:flex-none md:flex-row md:border-0 md:p-5 md:text-lg md:font-medium md:leading-[22px]",
        shouldAnimate && selected && "animate-wiggle",
        selected &&
          "border-t-3 border-t-textLink bg-interfaceBgDefault md:border-b-textLink md:text-textPrimary font-semibold md:border-b-4 md:border-t-0",
        !selected &&
          "bg-interfaceWhite  md:bg-interfaceBgDefault md:text-textSubdued",
      )}
      onClick={onSelect}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          event.preventDefault();
          onSelect();
        } else if (event.key === "ArrowRight" && onSelectNext) {
          event.preventDefault();
          onSelectNext();
        } else if (event.key === "ArrowLeft" && onSelectPrevious) {
          event.preventDefault();
          onSelectPrevious();
        }
      }}
      ref={ref}
      role="tab"
      tabIndex={selected ? 0 : -1}
      title={label}
    >
      <div className="relative flex flex-col items-center justify-center">
        <Icon className="h-[16px] w-[16px] md:mr-1.5" />
        {showUpdateIcon ? (
          <div className="bg-actionDefault absolute -right-2 top-0 h-1.5 w-1.5 rounded-full md:hidden" />
        ) : null}
      </div>
      <div className="relative flex flex-col items-center justify-center">
        {label}
        {showUpdateIcon ? (
          <div className="bg-actionDefault -right-1.5 top-0 hidden h-1.5 w-1.5 rounded-full md:absolute md:flex" />
        ) : null}
      </div>
    </button>
  );
}

export const TabItem = forwardRef(InnerTabItem);
