import { X } from "lucide-react";

import { Button, Dialog, DialogContent, DialogClose } from "shared/components";

export interface ReportSubmittedForReviewDialogProps {
  email: string;
  onOpenChange: (open: boolean) => void;
  open: boolean;
}

export function ReportSubmittedForReviewDialog({
  email,
  onOpenChange,
  open,
}: ReportSubmittedForReviewDialogProps) {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent
        className="max-w-[520px] gap-0 rounded-3xl border-0 p-0"
        hideCloseButton
      >
        <DialogClose
          aria-label="Close"
          className="absolute end-[16px] top-[16px]"
          role="button"
        >
          <X className="text-textPrimary h-[24px] w-[24px]" />
        </DialogClose>
        <div className="bg-interfaceWhite flex flex-col rounded-[20px] px-[60px] py-[48px] shadow-sm">
          <div className="text-textPrimary flex items-center gap-2 text-[28px] font-semibold leading-[1.14]">
            📫 Submitted for review!
          </div>
          <div className="mt-[20px] flex flex-col gap-[8px]">
            <p className="text-textPrimary text-lg leading-[1.2222]">
              We’ve received your request and will review your report shortly.
            </p>
            <p className="text-textPrimary text-lg leading-[1.2222]">
              Once the report has been reviewed, you’ll receive a shareable link
              to it by email (<span className="font-semibold">{email}</span>
              ).
            </p>
          </div>
          <div className="mt-[24px] flex justify-end gap-[12px]">
            <Button
              className="border border-blue-600 text-blue-600"
              onClick={() => onOpenChange(false)}
              variant="outline"
            >
              Close
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
