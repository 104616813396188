import { useEffect, useState } from "react";

import { MyLotCopyIcon } from "@/components/icons";

interface CopyLinkButtonProps {
  link: string;
}

export function CopyLinkButton({ link }: CopyLinkButtonProps) {
  const [text, setText] = useState("Copy public share link");

  useEffect(() => {
    let timerId: any;

    if (text === "Copied!") {
      timerId = setTimeout(() => {
        setText("Copy public share link");
      }, 2000); // 2 seconds
    }

    return () => clearTimeout(timerId);
  }, [text]);

  return (
    <button
      onClick={() => {
        setText("Copied!");
        navigator.clipboard.writeText(link);
      }}
      className="text-textLink hover:text-textLinkHover flex flex-row text-base leading-5 underline print:hidden"
    >
      <div className="mr-0.5 h-5 w-5">
        <MyLotCopyIcon className="text-textLink hover:text-textLinkHover" />
      </div>
      {text}
    </button>
  );
}
