export interface ReportGeneratedPanelProps {
  className?: string;
  onClickAdjustProposals: () => void;
  onClickSubmit: () => void;
}

export function ReportGeneratedPanel({
  className,
  onClickAdjustProposals,
  onClickSubmit,
}: ReportGeneratedPanelProps) {
  return (
    <div className={className}>
      <p className="text-textPrimary text-base leading-[20px]">
        If there are assumptions in the report you’d like to clarify, or if
        you’d like to change something, you can{" "}
        <span
          className="text-textLink cursor-pointer underline"
          onClick={onClickAdjustProposals}
          role="button"
          tabIndex={0}
        >
          adjust your plans
        </span>
        .
      </p>
      <p className="text-textPrimary mt-[8px] text-base leading-[20px]">
        <span className="font-bold">This is a draft report.</span> Once you’re
        happy with your proposal and how it’s been interpreted,{" "}
        <span
          className="text-textLink cursor-pointer underline"
          onClick={onClickSubmit}
          role="button"
          tabIndex={0}
        >
          submit it for review
        </span>
        .
      </p>
    </div>
  );
}
