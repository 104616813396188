import { type FormEvent } from "react";

import { Input } from "shared/components";
import { IconSpinner } from "@/components/icons";

interface NewPasswordProps {
  errorMsg: string;
  isLoading: boolean;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export function ResetPassword({
  errorMsg,
  isLoading,
  onSubmit,
}: NewPasswordProps) {
  return (
    <div className="flex flex-col">
      <h1 className="mb-6 text-left text-[32px] font-semibold leading-10">
        Reset password
      </h1>
      <div className="flex">
        <form onSubmit={onSubmit} className="w-full">
          <div className="flex flex-col gap-y-3">
            <Input
              autoCapitalize="none"
              autoComplete="password"
              autoCorrect="off"
              className="border-interactiveBorder bg-interfaceWhite placeholder:text-textSubdued text-textPrimary rounded-lg p-4 text-base leading-5"
              disabled={isLoading}
              id="password"
              name="password"
              placeholder="New password"
              type="password"
            />
            <Input
              autoCapitalize="none"
              autoComplete="none"
              autoCorrect="off"
              className="border-interactiveBorder bg-interfaceWhite placeholder:text-textSubdued text-textPrimary rounded-lg p-4 text-base leading-5"
              disabled={isLoading}
              id="passwordConfirm"
              name="passwordConfirm"
              placeholder="Confirm new password"
              type="password"
            />
          </div>
          <button
            className="bg-actionDefault text-textWhite mt-4 flex w-full flex-row items-center justify-center rounded px-4 py-2 text-lg font-semibold leading-[22px]"
            disabled={isLoading}
          >
            Reset password
            {isLoading && <IconSpinner className="ml-1 h-4 w-4 animate-spin" />}
          </button>
          <div
            hidden={errorMsg == ""}
            className="border-destructiveBorder bg-destructiveBgDefault text-destructiveText mt-6 rounded border px-4 py-2 text-center text-base"
          >
            {errorMsg}
          </div>
        </form>
      </div>
    </div>
  );
}
