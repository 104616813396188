import { Loader2 } from "lucide-react";

import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from "shared/components";

export interface MarkAsDoneDialogProps {
  email: string;
  onOpenChange: (open: boolean) => void;
  onSubmit: () => void;
  open: boolean;
  submitting: boolean;
}

export function MarkAsDoneDialog({
  email,
  open,
  onOpenChange,
  onSubmit,
  submitting,
}: MarkAsDoneDialogProps) {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent
        aria-describedby={undefined}
        className="flex max-h-[80%] flex-col gap-6 p-6 md:gap-8 md:p-8"
      >
        <DialogTitle>Mark proposal as done</DialogTitle>
        <div>
          <p className="text-bodylg text-textPrimary mb-2">
            If you’re happy with your proposal and how it’s been analysed, mark
            it as done and we’ll send a shareable link to it by email ({email}){" "}
            after it’s been processed.
          </p>
          <p className="text-bodylg text-textPrimary">
            We’ll also keep a record of the analysis on our end, so if you’d
            like to call us about it or have it reviewed, we’ll have it on hand.
          </p>
        </div>

        <DialogFooter className="flex justify-end gap-3">
          <Button onClick={() => onOpenChange(false)} variant="outline">
            Cancel
          </Button>
          <Button onClick={onSubmit} variant="default">
            {submitting ? <Loader2 className="mr-2 animate-spin" /> : null}
            Mark proposal as done
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
