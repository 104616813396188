import {
  IconGreenCheck,
  IconYellowPermit,
  IconRedProhibited,
  IconGreyQuestion,
} from "@/components/icons";

export const CallOutTypes = {
  WHITE: "WHITE",
  GREEN: "GREEN",
  YELLOW: "YELLOW",
  RED: "RED",
  GREY: "GREY",
} as const
export type CallOutTypes = typeof CallOutTypes[keyof typeof CallOutTypes];

export interface CallOutProps {
  header: string;
  text: string;
  type: CallOutTypes;
}

export function CallOut({ header, text, type }: CallOutProps) {
  return (
    <div>
      {type === CallOutTypes.WHITE && (
        <div className="border-interactiveBorder content-start justify-start rounded-lg border bg-white p-5 md:px-10 md:py-8">
          <h2 className="text-textPrimary mb-2 text-2xl font-semibold">
            {header}
          </h2>
          <p className="text-textPrimary text-lg leading-[22px]">
            {text}
          </p>
        </div>
      )}
      {type === CallOutTypes.GREEN && (
        <div className="border-successBorder bg-successBgLight content-start justify-start rounded-t-md border p-5 md:px-10 md:py-8">
          <div className="inline-flex">
            <div className="mr-2 mt-[6px] h-[128px] w-[128px] md:h-[64px] md:w-[64px]">
              <IconGreenCheck className="" />
            </div>

            <div className="">
              <div className="text-successHover text-2xl font-semibold">
                {header}
              </div>
            </div>
          </div>
        </div>
      )}
      {type === CallOutTypes.YELLOW && (
        <div className="border-warningBorder bg-warningBgLight content-start justify-start rounded-t-md border p-5 md:px-10 md:py-8">
          <div className="inline-flex">
            <div className="mr-2  h-[32px] w-[32px]">
              <IconYellowPermit className="" />
            </div>
            <div className="">
              <div className="text-2xl font-semibold text-[#D38A00]">
                {header}
              </div>
            </div>
          </div>
        </div>
      )}
      {type === CallOutTypes.RED && (
        <div className="border-destructiveBorder bg-destructiveBgLight content-start justify-start rounded-t-md  border p-5 md:px-10 md:py-8">
          <div className="inline-flex">
            <div className="mr-2 mt-[2px] h-[32px] w-[32px]">
              <IconRedProhibited className="" />
            </div>
            <div className="">
              <div className="text-2xl font-semibold text-[#C92400]">
                {header}
              </div>
            </div>
          </div>
        </div>
      )}
      {type === CallOutTypes.GREY && (
        <div className="border-interactiveBorder content-start justify-start rounded-t-md  border bg-[#F1F0ED] p-5 md:px-10 md:py-8">
          <div className="inline-flex">
            <div className="mr-2 mt-[4px] h-[32px] w-[32px]">
              <IconGreyQuestion className="" />
            </div>
            <div className="">
              <div className="mb-2  text-2xl font-semibold text-[#373530]">
                {header}
              </div>
              <div className="text-textPrimary text-lg leading-[22px]">
                {text}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
