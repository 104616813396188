import { useEffect, useRef, useState } from "react";
import { useAtomValue, useSetAtom } from "jotai";
import { Loader, MapPin, ArrowRight, Loader2 } from "lucide-react";
import { useQueryClient } from "@tanstack/react-query";

import {
  applicationPageVar,
  Pages,
  panelFlowTypeVar,
  Sections,
  wizardNavigatorVar,
} from "@/variables/globalVar";
import { useTracking } from "@/hooks/useTracking";
import { getAccountQueryKey, useCreateApplication } from "shared/apiHooks";
import { useAccount } from "@/hooks/useAccount";

import { Button } from "shared/components";
import {
  AddressContainer,
  type AddressContainerAPI,
} from "@/components/address";

export function AddressPage() {
  const queryClient = useQueryClient();

  const wizardNavigator = useAtomValue(wizardNavigatorVar);
  const panelFlowType = useAtomValue(panelFlowTypeVar);
  const setApplicationPageVar = useSetAtom(applicationPageVar);

  const [selectedAddressId, setSelectedAddressId] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const addressContainerRef = useRef<AddressContainerAPI>(null);

  const { account, application, conversation } = useAccount();

  const { stateChange } = useTracking("<AddressPage>");

  const { isPending: createApplicationLoading, mutate: createApplication } =
    useCreateApplication({
      onSuccess: (response) => {
        console.log(
          "<AddressPage> createApplication() onSuccess",
          response.data,
        );
        queryClient.invalidateQueries({ queryKey: getAccountQueryKey() });
      },
    });

  useEffect(() => {
    const abortCreateApplication = new AbortController();

    if (account && !application && !createApplicationLoading) {
      setTimeout(() => {
        console.log("<AddressPage> createApplication()");
        createApplication({
          body: { panel_flow_type: panelFlowType },
          signal: abortCreateApplication.signal,
        });
      }, 0);
    }

    return () => {
      abortCreateApplication.abort();
    };
  }, [account, application]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (conversation?.address_data) {
      setApplicationPageVar(Pages.App);
    }
  }, [conversation]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    stateChange("/wizard");
  }, [stateChange]);

  return (
    <>
      {createApplicationLoading ||
        (!application && (
          <div
            className="flex h-full w-full items-center justify-center"
            data-testid="address-page-loading-indicator"
          >
            <Loader className="text-textSubdued h-[48px] w-[48px] animate-spin" />
          </div>
        ))}

      {wizardNavigator === Sections.Address &&
        !createApplicationLoading &&
        application && (
          <div className="bg-interfaceWhite flex h-full w-full flex-col items-center p-6 md:p-12">
            <div className="grow" />

            <div className="flex w-full flex-col items-start overflow-visible md:max-w-[680px]">
              <h1 className="text-textPrimary text-headingxl pb-2 font-semibold">
                👋 Let’s start with an address
              </h1>

              <p className="text-textSubdued text-bodylg pb-8">
                We’ll use the address to determine the lot’s size, zone and
                planning overlays.
              </p>

              <AddressContainer
                onSelectedAddress={setSelectedAddressId}
                onSubmitAddressCompleted={() => setIsSubmitting(false)}
                onSubmitAddressFailed={() => setIsSubmitting(false)}
                placeholder="Enter an address"
                prefixIcon={<MapPin />}
                ref={addressContainerRef}
                showSelectButton={false}
              />
            </div>

            <div className="grow" />

            <div className="flex w-full justify-end">
              <Button
                disabled={!selectedAddressId || isSubmitting}
                onClick={() => {
                  setIsSubmitting(true);
                  addressContainerRef.current?.submitSelectedAddress();
                }}
                size="sm"
              >
                {isSubmitting && <Loader2 className="mr-2 animate-spin" />}
                Continue
                <ArrowRight className="ml-2" size={16} />
              </Button>
            </div>
          </div>
        )}
    </>
  );
}
