// import { useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { useSetAtom } from "jotai";

// import { FlowType } from "shared/types";
// import { panelFlowTypeVar } from "@/variables/globalVar";

import { Layout } from "@/components/Layout";
import { App } from "@/App";
import { ErrorPage } from "@/pages/ErrorPage";
import { LandingPage } from "@/pages/LandingPage";
import { PublicViewPage } from "@/pages/PublicViewPage";
import { ResetPasswordPage } from "@/pages/ResetPasswordPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Layout>
        <App />
      </Layout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/demo",
    element: <Navigate to="/" replace={true} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/reset-password",
    element: (
      <Layout>
        <ResetPasswordPage />
      </Layout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "yarra-ranges",
    element: <LandingPage />,
  },
  {
    path: "report",
    element: <PublicViewPage />,
  },
]);

export function Router() {
  // const setPanelFlowTypeVar = useSetAtom(panelFlowTypeVar);

  // useEffect(() => {
  //   if (window.location.pathname.startsWith("/demo")) {
  //     setPanelFlowTypeVar(FlowType.fast_enquiry);
  //   } else if (window.location.pathname.startsWith("/full")) {
  //     setPanelFlowTypeVar(FlowType.full_assessment);
  //   }
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <RouterProvider router={router} />;
}
