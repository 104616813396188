import { ComponentProps } from "react";

export function Background({ className }: ComponentProps<"svg">) {
  return (
    <svg
      width="342"
      height="406"
      viewBox="0 0 342 406"
      fill="none"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_6979_4474)">
        <rect width="342" height="406" fill="#EFF7FF" />
        <ellipse
          cx="171"
          cy="375.381"
          rx="262.969"
          ry="76.381"
          fill="#D2EFE6"
        />
      </g>
      <defs>
        <clipPath id="clip0_6979_4474">
          <rect width="342" height="406" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
