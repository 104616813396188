import { useMemo } from "react";
import { useAtomValue, useSetAtom } from "jotai";
import { useQueryClient } from "@tanstack/react-query";
import { Loader2 } from "lucide-react";

import { AccountType, MessageType } from "shared/types";
import {
  applicationPageVar,
  messagesVar,
  Pages,
  panelFlowTypeVar,
  Sections,
  wizardNavigatorVar,
} from "@/variables/globalVar";

import { getAccountQueryKey, useCreateApplication } from "shared/apiHooks";
import { useMessages } from "@/hooks";

import { Button, Dialog, DialogContent, DialogTitle } from "shared/components";

export function OffTopicPanel() {
  const queryClient = useQueryClient();

  const panelFlowType = useAtomValue(panelFlowTypeVar);
  const setWizardNavigatorVar = useSetAtom(wizardNavigatorVar);
  const setApplicationPageVar = useSetAtom(applicationPageVar);
  const setMessagesVar = useSetAtom(messagesVar);

  const { messages } = useMessages();
  const offTopicMessage = useMemo(() => {
    return messages.filter(
      (message) =>
        message.sender_account_type === AccountType.ASSISTANT &&
        message.message_type === MessageType.OFF_TOPIC,
    )[0];
  }, [messages]);

  const { isPending: createApplicationLoading, mutate: createApplication } =
    useCreateApplication({
      onError: (error) => {
        console.error("<OffTopicPanel> createApplication() onError", error);
      },
      onSuccess: (response) => {
        console.log(
          "<OffTopicPanel> createApplication() onSuccess",
          response.data,
        );
        setMessagesVar([]);
        setApplicationPageVar(Pages.Address);
        setWizardNavigatorVar(Sections.Address);
        queryClient.invalidateQueries({ queryKey: getAccountQueryKey() });
      },
    });

  const handleClickRestart = () => {
    if (createApplicationLoading) {
      return;
    }

    console.log("<OffTopicPanel> handleClickRestart()");
    const abortCreateApplication = new AbortController();
    createApplication({
      body: { panel_flow_type: panelFlowType },
      signal: abortCreateApplication.signal,
    });
  };

  return (
    <div className="flex h-full flex-col">
      <Dialog open>
        <DialogContent
          aria-describedby={undefined}
          className="max-w-[600px] gap-0 rounded-none border-0 p-0 px-5"
          hideCloseButton
        >
          <DialogTitle className="sr-only">
            This service can’t assist with your enquiry
          </DialogTitle>

          <div className="bg-interfaceWhite flex flex-col rounded-3xl pt-5 md:pt-6">
            <h2 className="text-textPrimary md:px-15 px-8 text-[28px] font-semibold leading-8">
              This service can’t assist with your enquiry
            </h2>

            <div className="md:px-15 mb-4 mt-3 flex flex-col gap-[16px] px-8">
              <p
                className="text-bodymd text-textPrimary [&>a]:text-textLink [&>a]:hover:text-textLinkHover [&>a]:underline"
                dangerouslySetInnerHTML={{
                  __html: offTopicMessage?.text || "",
                }}
              />

              <div className="flex justify-end gap-[12px]">
                <Button
                  className="text-actionDefault border-actionBorder"
                  onClick={handleClickRestart}
                  variant="outline"
                >
                  {createApplicationLoading && (
                    <>
                      <Loader2 className="animate-spin" />{" "}
                    </>
                  )}
                  Restart
                </Button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <div className="flex-grow" />

      <div className="mt-3 flex justify-end">
        <Button disabled>Next</Button>
      </div>
    </div>
  );
}
