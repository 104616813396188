import { useCouncilLocalisation } from "shared/hooks";
import { useAccount } from "@/hooks";

import { MyLotCopyIcon } from "@/components/icons";

export interface ReportReviewedPanelProps {
  conversationId: string;
  className?: string;
  email: string;
}

export function ReportReviewedPanel({
  conversationId,
  className,
  email,
}: ReportReviewedPanelProps) {
  const { conversation } = useAccount();
  const { getLocalisationEntry } = useCouncilLocalisation(conversation);

  const publicReportUrl = window.location.href + "report?id=" + conversationId;

  const handleClickCopyToClipboard = () => {
    navigator.clipboard.writeText(publicReportUrl);
  };

  return (
    <div
      className={`bg-interfaceWhite flex flex-col rounded-sm border-l-[4px] px-[28px] py-[24px] ${className}`}
    >
      <h1 className="text-xl font-semibold">
        ✅ Your report has been reviewed
      </h1>
      <p>
        We’ve emailed a shareable link to the reviewed report to{" "}
        <span className="font-semibold">{email}</span> and sent a copy to{" "}
        {getLocalisationEntry("councilName")} for their records.
      </p>
      <div className="border-interactiveBorder text-textLink mt-[8px] flex w-full items-center justify-between rounded border px-2 py-1.5 text-lg leading-4 md:text-nowrap">
        <span>{publicReportUrl}</span>
        <span className="bg-actionBgDefault rounded-[2px]">
          <span
            className="cursor-pointer"
            onClick={handleClickCopyToClipboard}
            role="button"
            tabIndex={0}
          >
            <MyLotCopyIcon />
          </span>
        </span>
      </div>
      <h1 className="pt-[16px] text-xl font-semibold">
        Want to chat to us about your report?
      </h1>
      <p>
        <span className="font-semibold">
          Call {getLocalisationEntry("councilPhoneNumber")}
        </span>{" "}
        or{" "}
        <a
          className="text-textLink underline"
          href={`mailto:${getLocalisationEntry("councilContactLink")}`}
        >
          contact us
        </a>{" "}
        to speak to one of our planners.
      </p>
      <p>When you contact us, tell us your report ID:</p>
      <div className="border-interactiveBorder text-textPrimary mt-[8px] w-min rounded border px-2 py-1.5 text-lg leading-4 tracking-[2.7px] md:text-nowrap">
        {conversationId}
      </div>
    </div>
  );
}
