import { useEffect, useState } from "react";

const TEXT_ANIMATION_SPEED = 50;

const loadingMessages = [
  "🤓 Analysing your response...",
  "🔎 Checking the planning scheme...",
  "✍️ Crafting the next best question...",
];

export function PromptFormLoadingMessage() {
  const [loadingMessage, setLoadingMessage] = useState("");
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);
  const [currentLoadingMessage, setCurrentLoadingMessage] = useState(
    loadingMessages[0],
  );

  useEffect(() => {
    if (loadingMessageIndex < currentLoadingMessage.length) {
      const timeout = setTimeout(() => {
        setLoadingMessage(
          (prevText) => prevText + currentLoadingMessage[loadingMessageIndex],
        );
        setLoadingMessageIndex((prevIndex) => prevIndex + 1);
      }, TEXT_ANIMATION_SPEED);

      return () => {
        clearTimeout(timeout);
        // isLoadingVar(false);
      };
    } else {
      setTimeout(() => {
        setLoadingMessage("\u00A0 ");
        setCurrentLoadingMessage(
          loadingMessages[
            (loadingMessages.indexOf(currentLoadingMessage) + 1) %
              loadingMessages.length
          ],
        );
        setLoadingMessageIndex(0);
      }, 500);
    }
  }, [currentLoadingMessage, loadingMessage, loadingMessageIndex]);

  return <>{loadingMessage}</>;
}
