import { useAtomValue } from "jotai";

import { AuthFlow, authFlowSelectedVar } from "@/variables/globalVar";

import { ForgotPassword } from "@/components/ForgotPassword";
import { Login } from "@/components/Login";
import { SignUp } from "@/components/SignUp";

export function AuthPage() {
  const authFlowSelected = useAtomValue(authFlowSelectedVar);

  return (
    <div className="flex h-full w-full items-center justify-center overflow-auto">
      <div className="w-full md:max-w-[400px]">
        {authFlowSelected === AuthFlow.ForgotPassword ? (
          <ForgotPassword />
        ) : null}
        {authFlowSelected === AuthFlow.Login ? <Login /> : null}
        {authFlowSelected === AuthFlow.Signup ? <SignUp /> : null}
      </div>
    </div>
  );
}
