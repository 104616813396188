export interface ReportSubmittedForReviewPanelProps {
  email: string;
}

export function ReportSubmittedForReviewPanel({
  email,
}: ReportSubmittedForReviewPanelProps) {
  return (
    <div className="bg-interfaceWhite flex flex-col rounded-sm border-l-[4px] px-[28px] py-[24px]">
      <h1 className="text-xl font-semibold">
        📫 Your report has been submitted for review
      </h1>
      <p>
        We’ve received your request and will review your report shortly. Once
        the report has been reviewed, you’ll receive a shareable link to it by
        email (<span className="font-semibold">{email}</span>).
      </p>
    </div>
  );
}
