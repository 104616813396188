import { useEffect, useRef, useState } from "react";
import { useAtom, useAtomValue } from "jotai";
import { DraftingCompass, Home, ListChecks } from "lucide-react";

import {
  Tabs,
  tabAnimationVar,
  tabNotificationsVar,
  tabSelectedVar,
} from "@/variables/globalVar";
import { TabItem } from "./TabItem";

export function WebTabs() {
  const [tabSelected, setTabSelectedVar] = useAtom(tabSelectedVar);
  const [tabNotifications, setTabNotificationsVar] =
    useAtom(tabNotificationsVar);
  const tabAnimation = useAtomValue(tabAnimationVar);

  const lotTabRef = useRef<HTMLButtonElement>(null);
  const proposalTabRef = useRef<HTMLButtonElement>(null);
  const permissibilityTabRef = useRef<HTMLButtonElement>(null);

  const [animatedTab, setAnimatedTab] = useState("");

  useEffect(() => {
    switch (tabSelected) {
      case Tabs.Chat:
        //tabNotifs({chat:false, lot:tabNotifications.lot, proposal: tabNotifications.proposal, permissibility: tabNotifications.permissibility});
        break;
      case Tabs.Lot:
        setTabNotificationsVar({
          chat: tabNotifications.chat,
          lot: false,
          proposal: tabNotifications.proposal,
          permissibility: tabNotifications.permissibility,
        });
        break;
      case Tabs.Proposal:
        setTabNotificationsVar({
          chat: tabNotifications.chat,
          lot: tabNotifications.lot,
          proposal: false,
          permissibility: tabNotifications.permissibility,
        });
        setAnimatedTab("Proposal");
        break;
      case Tabs.Permissibility:
        setTabNotificationsVar({
          chat: tabNotifications.chat,
          lot: tabNotifications.lot,
          proposal: tabNotifications.proposal,
          permissibility: false,
        });
        setAnimatedTab("Permissibility");
        break;

      default:
    }

    const timeout = setTimeout(() => {
      setAnimatedTab("");
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [tabSelected]); // eslint-disable-line

  const handleSelectNextTab = () => {
    switch (tabSelected) {
      case Tabs.Lot:
        setTabSelectedVar(Tabs.Proposal);
        proposalTabRef.current?.focus();
        break;
      case Tabs.Proposal:
        setTabSelectedVar(Tabs.Permissibility);
        permissibilityTabRef.current?.focus();
        break;
      case Tabs.Permissibility:
        setTabSelectedVar(Tabs.Lot);
        lotTabRef.current?.focus();
        break;
      default:
    }
  };

  const handleSelectPreviousTab = () => {
    switch (tabSelected) {
      case Tabs.Lot:
        setTabSelectedVar(Tabs.Permissibility);
        permissibilityTabRef.current?.focus();
        break;
      case Tabs.Permissibility:
        setTabSelectedVar(Tabs.Proposal);
        proposalTabRef.current?.focus();
        break;
      case Tabs.Proposal:
        setTabSelectedVar(Tabs.Lot);
        lotTabRef.current?.focus();
        break;
      default:
    }
  };

  return (
    <div
      className="border-interfaceBorder px-15 flex w-full flex-row border-b"
      role="tablist"
    >
      <TabItem
        icon={Home}
        label="Lot"
        onSelect={() => setTabSelectedVar(Tabs.Lot)}
        onSelectNext={handleSelectNextTab}
        onSelectPrevious={handleSelectPreviousTab}
        ref={lotTabRef}
        selected={tabSelected === "lot"}
        showUpdateIcon={tabNotifications.lot}
        shouldAnimate={tabAnimation && animatedTab === "Lot"}
      />
      <TabItem
        icon={DraftingCompass}
        label="Proposal"
        onSelect={() => setTabSelectedVar(Tabs.Proposal)}
        onSelectNext={handleSelectNextTab}
        onSelectPrevious={handleSelectPreviousTab}
        ref={proposalTabRef}
        selected={tabSelected === "proposal"}
        showUpdateIcon={tabNotifications.proposal}
        shouldAnimate={tabAnimation && animatedTab === "Proposal"}
      />
      <TabItem
        icon={ListChecks}
        label="Report"
        onSelect={() => setTabSelectedVar(Tabs.Permissibility)}
        onSelectNext={handleSelectNextTab}
        onSelectPrevious={handleSelectPreviousTab}
        ref={permissibilityTabRef}
        selected={tabSelected === "permissibility"}
        showUpdateIcon={tabNotifications.permissibility}
        shouldAnimate={tabAnimation && animatedTab === "Permissibility"}
      />
    </div>
  );
}
