import { useEffect } from "react";
import { useAtomValue } from "jotai";

import { tabAnimationVar } from "@/variables/globalVar";

import { useTracking } from "@/hooks/useTracking";

import { ProposalPanel } from "./ProposalPanel";

export function ProposalContainer() {
  const tabAnimation = useAtomValue(tabAnimationVar);

  const { stateChange } = useTracking("<ProposalContainer>");

  useEffect(() => {
    stateChange("/plans");
  }, [stateChange]);

  return (
    <div className="bg-interfaceBgDefault h-full min-h-0 w-full grow items-start overflow-y-auto">
      <div
        className={`${tabAnimation ? "animate-slide-in" : ""} flex w-full flex-col p-6 md:px-15 md:py-10`}
      >
        <ProposalPanel />
      </div>
    </div>
  );
}
