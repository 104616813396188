import { useAtom } from "jotai";
import { DraftingCompass, ListChecks } from "lucide-react";

import {
  Tabs,
  tabSelectedVar,
} from "@/variables/globalVar";

import { TabItem } from "@/components/TabItem";

export function WizardTabs() {
  const [tabSelected, setTabSelectedVar] = useAtom(tabSelectedVar);

  return (
    <div className="border-interactiveBorder flex w-full flex-row border-t justify-center bg-interfaceBgLight">
      <TabItem
        icon={ListChecks}
        label="Permissibility"
        selected={tabSelected === "permissibility"}
        onSelect={() => setTabSelectedVar(Tabs.Permissibility)}
      />
      <TabItem
        icon={DraftingCompass}
        label="Lot and proposal"
        selected={tabSelected === "lotAndProposal"}
        onSelect={() => setTabSelectedVar(Tabs.LotAndProposal)}
      />
    </div>
  );
}
