import { useEffect, useRef, useState, type FormEvent } from "react";
import { useSetAtom } from "jotai";
import { useQueryClient } from "@tanstack/react-query";

import {
  AuthFlow,
  authFlowSelectedVar,
  isAuthenticatedVar,
} from "@/variables/globalVar";
import {
  getAccountQueryKey,
  useLogin,
  useGetAuthSocialList,
} from "shared/apiHooks";
import { type SocialApp } from "shared/apiClient";

import { IconSpinner } from "@/components/icons";
import {
  Button,
  Input,
  Label,
  Separator,
  SocialLoginButtons,
} from "shared/components";

export function Login() {
  const queryClient = useQueryClient();

  const setAuthFlowSelectedVar = useSetAtom(authFlowSelectedVar);
  const setAuthenticatedVar = useSetAtom(isAuthenticatedVar);

  const emailRef = useRef<HTMLInputElement | null>(null);
  const passwordRef = useRef<HTMLInputElement | null>(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [socialApps, setSocialApps] = useState<Array<SocialApp>>([]);

  const {
    data: authSocialListResponse,
    error: authSocialListError,
    isLoading: isLoadingAuthSocialList,
  } = useGetAuthSocialList();

  useEffect(() => {
    if (authSocialListError) {
      console.error(
        "<Login> useGetAuthSocialList() onError()",
        authSocialListError,
      );
      setErrorMsg("Failed to load social login options.");
    }
  }, [authSocialListError]);

  useEffect(() => {
    if (authSocialListResponse) {
      const results = authSocialListResponse.data?.results || [];
      const transformedResults = results.map((app) => ({
        ...app,
        login_url: app.login_url ?? "", // Fallback to an empty string if login_url is undefined
      }));
      setSocialApps(transformedResults);
    }
  }, [authSocialListResponse]);

  const { isPending, mutate } = useLogin({
    onError: (error) => {
      console.error("<Login> useLogin() onError()", error);
      setAuthenticatedVar(false);
      const response = error.response;
      if (response?.data && Array.isArray(response.data["non_field_errors"])) {
        setErrorMsg(response.data["non_field_errors"][0]);
      } else if (response?.data && "detail" in response?.data) {
        setErrorMsg(response.data.detail as string);
      } else {
        setErrorMsg(
          "There was an unexpected error logging you in. Please refresh your browser and try again, or contact support for assistance.",
        );
      }
    },
    onSuccess: (response) => {
      console.log("<Login> useLogin() onSuccess()", response);
      if (response.data) {
        setErrorMsg("");
        queryClient.setQueryData(getAccountQueryKey(), response);
        setAuthenticatedVar(true);
      }
    },
  });

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const email = emailRef.current?.value || "";
    const password = passwordRef.current?.value || "";

    setErrorMsg("");
    mutate({ body: { email, password } });
  };

  return (
    <div className="flex flex-col gap-6 p-6">
      <h1 className="text-[32px] font-semibold leading-10 tracking-tight">
        Log in
      </h1>

      <SocialLoginButtons socialApps={socialApps} disabled={isPending} />

      <div className="flex items-center gap-2">
        <Separator className="w-auto grow" />
        <span className="text-bodysm text-textSubdued">
          or log in with email
        </span>
        <Separator className="w-auto grow" />
      </div>

      <form onSubmit={handleSubmit}>
        <div className="grid gap-3">
          <div className="grid gap-1">
            <Label className="sr-only" htmlFor="email">
              Email
            </Label>
            <Input
              autoCapitalize="none"
              autoComplete="email"
              autoCorrect="off"
              className="border-interactiveBorder placeholder:text-textSubdued rounded-lg p-4 text-base leading-5"
              disabled={isPending}
              id="email"
              placeholder="Email address"
              ref={emailRef}
              required
              type="email"
            />
          </div>
          <div className="grid gap-1">
            <Label className="sr-only" htmlFor="password">
              Password
            </Label>
            <Input
              autoCapitalize="none"
              autoComplete="password"
              autoCorrect="off"
              className="border-interactiveBorder placeholder:text-textSubdued rounded-lg p-4 text-base leading-5"
              disabled={isPending}
              id="password"
              placeholder="Password"
              ref={passwordRef}
              required
              type="password"
            />
          </div>
        </div>

        <div className="grid gap-3">
          <div>
            <button
              type="button"
              onClick={(event) => {
                event.preventDefault();
                setAuthFlowSelectedVar(AuthFlow.ForgotPassword);
              }}
              className="leaing-[14px] text-textLink hover:text-textLinkHover mt-1 text-xs underline"
            >
              Forgot your password?
            </button>
          </div>

          <Button
            className="bg-actionDefault text-textWhite mt-1 rounded px-4 py-2 text-lg font-semibold leading-[22px]"
            disabled={isPending}
          >
            Log in
            {isPending && <IconSpinner className="mr-2 h-4 w-4 animate-spin" />}
          </Button>

          <p className="flex flex-row justify-center">
            <span className="pr-1 text-base leading-5">
              Don't have an account?
            </span>
            <button
              disabled={isPending}
              onClick={(event) => {
                event.preventDefault();
                setAuthFlowSelectedVar(AuthFlow.Signup);
              }}
              className="text-textLink text-base leading-5 underline"
            >
              Sign up
            </button>
          </p>

          <div
            hidden={errorMsg === ""}
            className="border-destructiveBorder bg-destructiveBgDefault text-destructiveText mt-6 rounded border p-3"
          >
            {errorMsg}
          </div>
        </div>
      </form>
    </div>
  );
}
