import { useCouncilLocalisation } from "shared/hooks";
import { useAccount } from "@/hooks";

export function HelpMessage() {
  const { conversation } = useAccount();
  const { getLocalisationEntry } = useCouncilLocalisation(conversation);

  return (
    <div className="text-bodylg text-textPrimary flex flex-col gap-[16px]">
      <div>
        <p className="">
          If have a question,{" "}
          <a
            className="text-textLink hover:text-textLinkHover underline"
            href={getLocalisationEntry("councilContactLink")}
            rel="noreferrer"
            tabIndex={0}
            target="_blank"
          >
            contact council
          </a>{" "}
          to speak to a planner. When you contact us, tell us your session ID:
        </p>
        <div className="border-interactiveBorder text-bodylg [letter-spacing: 2.8px] mt-2 w-min whitespace-nowrap rounded border px-2 py-1.5 font-medium leading-4">
          {conversation?.identifier?.toUpperCase() || ""}
        </div>
      </div>
      <p>
        You may also find an answer to your question in the{" "}
        <a
          className="text-textLink hover:text-textLinkHover underline"
          href={getLocalisationEntry("councilPlanningWebsite")}
          rel="noreferrer"
          tabIndex={0}
          target="_blank"
        >
          planning section
        </a>{" "}
        of the council’s website.
      </p>
    </div>
  );
}
