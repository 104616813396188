import { ComponentProps } from "react";

export function Person({ className, style }: ComponentProps<"svg">) {
  return (
    <svg
      width="31"
      height="74"
      viewBox="0 0 31 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <g clipPath="url(#clip0_6995_4923)">
        <path
          d="M6.92769 30.9729L7.58813 31.0046C7.61958 31.4575 7.68247 31.9601 7.68247 32.4401C7.68247 43.5931 7.62857 57.463 7.61059 68.6114C7.597 69.4517 7.64656 70.2918 7.75886 71.1246C7.88458 71.9292 8.2923 72.6617 8.90805 73.1893C9.52381 73.7169 10.3068 74.0045 11.1149 74C11.9221 74.0302 12.7138 73.7717 13.3501 73.2704C13.9865 72.7691 14.4265 72.0571 14.5923 71.2604C14.7221 70.4309 14.7777 69.5913 14.7586 68.7518C14.7586 62.0817 14.7586 52.6948 14.781 46.0247C14.8628 45.2962 15.0255 44.5792 15.2662 43.8874L15.9761 43.9553C16.1111 44.6794 16.1997 45.4114 16.2412 46.147C16.2681 52.1423 16.2412 60.8591 16.2412 66.8545C16.2051 68.2536 16.2501 69.6537 16.376 71.0476C16.5961 72.8589 18.0203 74 19.8264 73.9954C22.1402 73.9954 23.3712 72.551 23.3757 69.7027C23.3757 58.5452 23.3757 44.6753 23.3757 33.5223C23.3757 32.8023 23.452 32.0869 23.4925 31.3669H23.9193C24.1191 31.9893 24.2693 32.6268 24.3686 33.2733C24.6157 36.6241 24.8538 38.6166 25.02 41.9674C25.1278 44.15 26.1567 45.4949 27.9448 45.5492C29.7329 45.6036 31.0897 44.1047 30.9954 41.9267C30.9294 38.7952 30.6849 35.6701 30.2631 32.5669C29.688 28.4915 29.1174 24.3391 27.0642 20.6441C25.9186 18.5883 24.4494 16.6728 21.9515 16.5687C17.7193 16.3513 13.4647 16.3015 9.23248 16.4646C6.63117 16.5642 4.70827 18.3302 3.88161 20.6848C2.60053 24.1882 1.63078 27.7993 0.983783 31.4756C0.327841 35.4921 0.206533 38.2679 0.00435875 42.3433C-0.0217359 42.7518 0.0332535 43.1614 0.166105 43.5483C0.298957 43.9351 0.507007 44.2914 0.778086 44.5962C1.04917 44.9011 1.37783 45.1484 1.74486 45.3237C2.11189 45.499 2.50992 45.5988 2.91566 45.6172C4.59595 45.6534 5.87639 44.2587 5.94827 42.3252C5.9887 41.1478 5.91233 41.3244 6.01567 40.1516C6.28523 37.0498 6.61769 34.0114 6.92769 30.9729ZM8.33842 7.53945C8.50465 11.7733 11.6945 14.8299 15.7515 14.6442C19.8084 14.4586 22.7018 11.0624 22.531 6.83305C22.3738 3.02935 18.82 -0.217375 15.0596 0.0135642C13.1819 0.123546 11.423 0.975495 10.1646 2.38449C8.90629 3.79348 8.25008 5.64582 8.33842 7.53945Z"
          fill="white"
        />
        <path
          d="M6.92789 30.9722C6.61789 34.0106 6.28542 37.0491 6.02934 40.092C5.926 41.2648 6.00238 41.0882 5.96195 42.2656C5.89006 44.2172 4.61412 45.5938 2.92934 45.5576C2.5236 45.5392 2.12557 45.4394 1.75853 45.2641C1.3915 45.0888 1.06284 44.8415 0.791758 44.5366C0.520679 44.2318 0.312628 43.8755 0.179777 43.4887C0.0469254 43.1019 -0.00806405 42.6922 0.0180306 42.2837C0.220205 38.2083 0.341512 35.4551 0.997455 31.416C1.64445 27.7397 2.6142 24.1286 3.89528 20.6252C4.72195 18.2706 6.64485 16.5046 9.24615 16.405C13.4783 16.2419 17.733 16.2917 21.9651 16.5091C24.4631 16.6359 25.9322 18.5513 27.0779 20.5845C29.1311 24.2795 29.7017 28.4228 30.2767 32.5073C30.6986 35.6105 30.9431 38.7356 31.009 41.8671C31.0944 44.0587 29.7196 45.5576 27.9585 45.4896C26.1973 45.4217 25.1415 44.0904 25.0337 41.9078C24.8675 38.557 24.6293 36.5645 24.3822 33.2137C24.283 32.5672 24.1328 31.9297 23.933 31.3073H23.5061C23.4657 32.0273 23.3893 32.7427 23.3893 33.4627C23.3893 44.6157 23.3893 58.4856 23.3893 69.6431C23.3893 72.4914 22.1538 73.9268 19.8401 73.9359C18.043 73.9359 16.6098 72.8083 16.3896 70.988C16.2637 69.5941 16.2187 68.194 16.2548 66.7949C16.2548 60.7995 16.2548 50.7243 16.2548 44.7289C16.2548 43.9953 14.7992 43.8912 14.7947 44.6066C14.7588 51.2767 14.7947 62.0221 14.7722 68.6922C14.7914 69.5317 14.7358 70.3713 14.606 71.2008C14.4401 71.9975 14.0002 72.7095 13.3638 73.2108C12.7275 73.7121 11.9357 73.9706 11.1286 73.9404C10.3205 73.9449 9.53748 73.6573 8.92173 73.1297C8.30597 72.6021 7.89825 71.8696 7.77253 71.065C7.66023 70.2322 7.61067 69.3921 7.62427 68.5518C7.62427 57.4034 7.67369 43.5335 7.69615 32.3805C7.69615 31.9005 7.63325 31.4205 7.6018 30.945L6.92789 30.9722Z"
          fill="#6A6A69"
        />
        <path
          d="M8.33865 7.53945C8.25044 5.64285 8.90911 3.78791 10.1713 2.37841C11.4334 0.968906 13.1969 0.118954 15.0778 0.0135642C18.8382 -0.217375 22.392 3.02935 22.5492 6.83305C22.72 11.0624 19.7727 14.4631 15.7697 14.6442C11.7666 14.8253 8.52286 11.7733 8.33865 7.53945Z"
          fill="#6A6A69"
        />
      </g>
      <defs>
        <clipPath id="clip0_6995_4923">
          <rect width="31" height="74" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
