export function sortByDate(
  a: { created_at?: string } | null,
  b: { created_at?: string } | null,
): number {
  const dateA = new Date(a?.created_at || "");
  const dateB = new Date(b?.created_at || "");
  if (dateA < dateB) {
    return -1;
  }
  if (dateA > dateB) {
    return 1;
  }
  return 0;
}
