import { useEffect, useRef } from "react";
import { useAtomValue } from "jotai";

import { type Message } from "shared/apiClient";
import { AccountType } from "shared/types";
import { titleCase } from "shared/lib";
import { messagesVar, panelFlowTypeVar } from "@/variables/globalVar";

import { useAccount } from "@/hooks/useAccount";

import {
  MultiSelectStep,
  TreeDistanceStep,
  TreeSizeStep,
} from "@/components/wizard/widgets";
import { TextMessageWidget } from "./widgets";

export function ChatList() {
  const { conversation } = useAccount();

  const messages = useAtomValue(messagesVar);
  const panelFlowType = useAtomValue(panelFlowTypeVar);

  const scrollBox = useRef<HTMLDivElement>(null);
  const lastMessageRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // Ensure there's a last message and a scroll container
    if (!lastMessageRef.current || !scrollBox.current) {
      return;
    }

    const observer = new ResizeObserver((_entries) => {
      // Scroll to the bottom whenever the height of the last message changes
      if (scrollBox.current) {
        scrollBox.current.scrollTop = scrollBox.current.scrollHeight;
      }
    });

    // Observe the last message
    observer.observe(lastMessageRef.current);

    // Cleanup observer on component unmount or messages change
    return () => observer.disconnect();
  }, [messages]); // Dependency on messages ensures observer update

  const renderMessage = (
    message: Message,
    isFirst: boolean,
    isLast: boolean,
  ) => {
    switch (message.message_type) {
      case "STANDARD":
      case "AMENDMENT":
      case "OVERWRITE":
        return (
          <TextMessageWidget
            animated={
              message.sender_account_type === AccountType.ASSISTANT && isLast
            }
            hideTime={isFirst}
            message={message}
          />
        );
      case "CHOICES_QUESTION":
        return (
          <div>
            <div
              className={`mb-0.5 flex w-full flex-row items-center ${message.sender_account_type !== AccountType.ASSISTANT ? "justify-end" : "justify-start"}`}
            >
              <time className="text-textSubdued ml-1 text-xs leading-[18px]">
                {new Intl.DateTimeFormat("en-US", {
                  timeStyle: "short",
                }).format(new Date(message.created_at || ""))}
              </time>
            </div>

            <MultiSelectStep disabled={!isLast} message={message} />
          </div>
        );
      case "TREE_DIAMETER_QUESTION":
        return (
          <div>
            <div
              className={`mb-0.5 flex w-full flex-row items-center ${message.sender_account_type !== AccountType.ASSISTANT ? "justify-end" : "justify-start"}`}
            >
              <time className="text-textSubdued ml-1 text-xs leading-[18px]">
                {new Intl.DateTimeFormat("en-US", {
                  timeStyle: "short",
                }).format(new Date(message.created_at || ""))}
              </time>
            </div>
            <TreeSizeStep disabled={!isLast} message={message} />
          </div>
        );
      case "TREE_DISTANCE_QUESTION":
        return (
          <div>
            <div
              className={`mb-0.5 flex w-full flex-row items-center ${message.sender_account_type !== AccountType.ASSISTANT ? "justify-end" : "justify-start"}`}
            >
              <time className="text-textSubdued ml-1 text-xs leading-[18px]">
                {new Intl.DateTimeFormat("en-US", {
                  timeStyle: "short",
                }).format(new Date(message.created_at || ""))}
              </time>
            </div>
            <TreeDistanceStep disabled={!isLast} message={message} />
          </div>
        );
    }
  };

  return (
    <div ref={scrollBox} className="h-full min-h-0 grow overflow-y-auto p-5">
      {panelFlowType !== "fast_enquiry" ? (
        <div className="mb-0.5 flex flex-col pr-5 md:pr-20">
          <div className="text-textSubdued text-xs leading-[18px]">
            {new Intl.DateTimeFormat("en-US", {
              timeStyle: "short",
            }).format(new Date(conversation?.created_at || ""))}
          </div>
          <div className="text-textPrimary self-start rounded-lg bg-[#F5F5F5] px-5 py-2 text-lg leading-[22px]">
            Thanks, I’ve pulled up the lot details for{" "}
            {titleCase(conversation?.address_data?.unstructured_address || "")}.
            You can find a summary in the{" "}
            <span className="font-semibold">Lot tab</span>.
            <br />
            <br />
            Now I’ll guide you through a few questions. You can also ask me a
            question or just say “I don’t know”.
          </div>
        </div>
      ) : null}

      <div className="flex flex-col gap-3">
        {messages?.length > 0
          ? messages.map((message, index) => {
              const isFirstMessage = index === 0;
              const isLastMessage = index === messages.length - 1;

              return (
                <div
                  key={message.id}
                  ref={isLastMessage ? lastMessageRef : null}
                >
                  {renderMessage(message, isFirstMessage, isLastMessage)}
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
}
