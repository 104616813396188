import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { Loader2 } from "lucide-react";

import { cn } from "shared/lib";
import { type EndUserPanel } from "shared/apiClient";
import { expertPanelVar } from "@/variables/globalVar";

import { useGetConversationPanels } from "shared/apiHooks";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "shared/components";

export interface PanelVersionsDropdownProps {
  className?: string;
  disabled?: boolean;
}

export function PanelVersionsDropdown({
  className,
  disabled,
}: PanelVersionsDropdownProps) {
  const [expertPanel, setExpertPanelVar] = useAtom(expertPanelVar);

  const [isOpening, setIsOpening] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [expertPanels, setExpertPanels] = useState<Array<EndUserPanel>>([]);

  const {
    data: panelsResponse,
    error: panelsError,
    isLoading,
  } = useGetConversationPanels(
    { query: { page: 1, page_size: 9999, is_archived: "false" } },
    { enabled: isOpening },
  );

  useEffect(() => {
    if (panelsResponse) {
      const panels = panelsResponse.data.results || [];
      if (panels.length > 0) {
        const sortedPanels = [...panels].sort(
          (a, b) => (b.version || 0) - (a.version || 0),
        );
        setExpertPanels(sortedPanels);
      }
      setIsOpening(false);
      setIsOpened(true);
    }
  }, [panelsResponse]);

  useEffect(() => {
    if (panelsError) {
      console.error(
        "<PanelVersionsDropdown> useGetConversationPanels() error",
        panelsError,
      );
    }
  }, [panelsError]);

  if (disabled) {
    return <div className="flex items-center">{expertPanel?.version}</div>;
  }

  return (
    <div className={cn("mr-2 flex items-center", className)}>
      <Select
        disabled={disabled}
        onOpenChange={(open) => {
          if (open && !isLoading && expertPanels.length === 0) {
            setIsOpening(true);
          } else {
            setIsOpened(open);
          }
        }}
        onValueChange={(version) => {
          if (version !== "") {
            console.log(
              "<PanelVersionsDropdown>: Expert panel version selected",
              version,
            );
            const expertPanel = expertPanels.find(
              (p) => p.version?.toString() === version,
            );
            if (expertPanel) {
              setExpertPanelVar(expertPanel);
            }
          }
        }}
        open={isOpened}
        value={expertPanel?.version?.toString()}
      >
        <SelectTrigger>
          {isLoading && <Loader2 className="mr-2 animate-spin" />}
          <SelectValue placeholder="Panel version" />
        </SelectTrigger>
        <SelectContent className="bg-interfaceWhite max-h-[16rem] overflow-auto">
          {expertPanels.length === 0 ? (
            <SelectItem value={expertPanel?.version?.toString() || "-999"}>
              <div className="flex items-center">
                <span className="mr-1 font-bold">{expertPanel?.version}</span>
                <div className="ml-1 max-w-[150px] truncate">
                  {expertPanel?.description}
                </div>
              </div>
            </SelectItem>
          ) : null}

          {expertPanels.length > 0
            ? expertPanels.map((expertPanel, index) => (
                <SelectItem
                  className="hover:bg-interactiveBgHover"
                  key={`${index}-${expertPanel.version}`}
                  value={expertPanel.version?.toString() || ""}
                >
                  <div className="flex items-center">
                    <span className="mr-1 font-bold">
                      {expertPanel.version}
                    </span>
                    <div className="text-textSubdued ml-1 max-w-[150px] truncate">
                      {expertPanel.description}
                    </div>
                  </div>
                </SelectItem>
              ))
            : null}
        </SelectContent>
      </Select>
    </div>
  );
}
