import { useCallback } from "react";
import useHotjar from "react-use-hotjar";

export function useTracking(logPrefix?: string) {
  const { stateChange, initHotjar, ...rest } = useHotjar();

  const wrappedStateChange: typeof stateChange = useCallback(
    (relativePath, ...args) => {
      console.log(
        `${logPrefix ? `${logPrefix} ` : ""}Hotjar state change:`,
        relativePath,
      );

      return stateChange(relativePath, ...args);
    },
    [logPrefix, stateChange],
  );

  const initTracking = useCallback(() => {
    return initHotjar(4953279, 6, false);
  }, [initHotjar]);

  return { initTracking, stateChange: wrappedStateChange, ...rest };
}
