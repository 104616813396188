import { useState } from "react";
import {
  ArrowLeft,
  ArrowRight,
  Check,
  ChevronDown,
  ChevronUp,
  CircleHelp,
  Cog,
  DraftingCompass,
  ExternalLink,
  Hand,
  ListChecks,
  LoaderCircle,
  Loader2,
  LogOut,
  Mail,
  MapPin,
  Meh,
  MessageCircle,
  Minus,
  Plus,
  RotateCcw,
  SendHorizonal,
  Settings,
  X,
} from "lucide-react";

import { type Message } from "shared/apiClient";
import { type Question, type QuestionData } from "../lib";

import { useGetObjectsNearAddress } from "shared/apiHooks";
import { useMediaQuery } from "shared/hooks";
import { cn, sentenceCase } from "shared/lib";
import { createMessageEvent, useChannel } from "shared/channel";
import { useAccount } from "@/hooks";

import {
  Button,
  Input,
  MapComponent,
  Textarea,
  Toggle,
} from "shared/components";
import { LoadingOverlay } from "./LoadingOverlay";

import "./MultiSelectStep.css";

const iconMap = {
  "arrow-left": <ArrowLeft size={20} />,
  "arrow-right": <ArrowRight size={20} />,
  check: <Check size={20} />,
  "chevron-down": <ChevronDown size={20} />,
  "chevron-up": <ChevronUp size={20} />,
  "circle-help": <CircleHelp size={20} />,
  cog: <Cog size={20} />,
  "drafting-compass": <DraftingCompass size={20} />,
  "external-link": <ExternalLink size={20} />,
  hand: <Hand size={20} />,
  "list-checks": <ListChecks size={20} />,
  "loader-circle": <LoaderCircle size={20} />,
  "log-out": <LogOut size={20} />,
  mail: <Mail size={20} />,
  "map-pin": <MapPin size={20} />,
  meh: <Meh size={20} />,
  "message-circle": <MessageCircle size={20} />,
  minus: <Minus size={20} />,
  plus: <Plus size={20} />,
  "rotate-ccw": <RotateCcw size={20} />,
  "send-horizontal": <SendHorizonal size={20} />,
  settings: <Settings size={20} />,
  x: <X size={20} />,
};

const colorMap = {
  red: "text-destructiveText bg-interfaceWhite border-destructiveBorder hover:bg-destructiveBgHover data-[state=on]:bg-destructiveDefault data-[state=on]:text-interfaceWhite",
  green:
    "text-successText bg-interfaceWhite border-successBorder hover:bg-successBgHover data-[state=on]:bg-successDefault data-[state=on]:text-interfaceWhite",
  blue: "text-actionText bg-interfaceWhite border-actionBorder hover:bg-actionBgHover data-[state=on]:bg-actionDefault data-[state=on]:text-interfaceWhite",
  yellow:
    "text-warningText bg-interfaceWhite border-warningBorder hover:bg-warningBgHover data-[state=on]:bg-warningDefault data-[state=on]:text-warningText",
};

export interface MultiSelectStepProps {
  disabled?: boolean;
  message: Message;
  showLoading?: boolean;
}

export function MultiSelectStep({
  disabled,
  message,
  showLoading,
}: MultiSelectStepProps) {
  const questionData = message.question_data as QuestionData;
  const questions = questionData.questions || [];

  const { channel } = useChannel();

  const { conversation } = useAccount();

  const [submitting, setSubmitting] = useState(false);
  const [selectedChoices, setSelectedChoices] = useState<{
    [questionIndex: number]: Array<number>;
  }>(() => {
    return questions.reduce((acc, _, questionIndex) => {
      acc[questionIndex] = [];
      return acc;
    }, {});
  });
  const [freeText, setFreeText] = useState("");

  const shouldShowMap = !!(
    questionData.image_url && conversation?.address_data?.gps_latitude
  );

  const shouldRenderSideBySide = useMediaQuery({
    query: "(min-width: 1416px)",
  });

  const { data: nearObjectsResponse } = useGetObjectsNearAddress(
    {
      path: {
        address_id: conversation?.address_data?.id || "",
      },
    },
    {
      enabled: shouldShowMap,
    },
  );

  const handleClickNext = () => {
    if (channel && conversation?.id) {
      setSubmitting(true);

      let text = Object.entries(selectedChoices)
        .map(([questionIndex, choiceIndexes]) => {
          const question = questions[questionIndex];
          const choices = question.choices || [];

          return choiceIndexes.map(
            (choiceIndex) =>
              `${choices[choiceIndex].value} ${choices[choiceIndex].label}`,
          );
        })
        .flat()
        .join(", ");

      if (freeText && text) {
        text += `, ${freeText}`;
      } else if (freeText) {
        text += freeText;
      }

      const messageDescriptor = createMessageEvent({
        conversation_id: conversation.id,
        text,
      });

      console.log("<MultiSelectWidget> handleClickNext()", messageDescriptor);
      channel.sendMessage(messageDescriptor);
    }
  };

  const renderMap = () => {
    return (
      <MapComponent
        latitude={conversation?.address_data?.gps_latitude ?? undefined}
        longitude={conversation?.address_data?.gps_longitude ?? undefined}
        addressBoundary={conversation?.address_data?.boundary ?? undefined}
        nearObjects={nearObjectsResponse?.data.near_objects ?? undefined}
        className="mb-3 h-[200px] min-h-[200px] w-full rounded bg-[#E3E2E1] md:mb-6 md:h-[320px] md:min-h-[320px]"
      />
    );
  };

  const renderQuestion = (question: Question, questionIndex: number) => {
    const shouldRenderTextField = (question.text_field_lines || 0) > 0;

    const shouldRender =
      !question.dependency ||
      selectedChoices[
        question.dependency?.depends_on_question ?? 9999
      ]?.includes(question.dependency?.depends_on_choice ?? 9999);

    if (!shouldRender) {
      return null;
    }

    return (
      <div key={questionIndex}>
        {question.question_text ? (
          <h3 className="text-textPrimary text-lg font-semibold leading-[22px]">
            {question.question_text}
          </h3>
        ) : null}

        {question.question_subtitle ? (
          <p className="text-textSubdued text-base leading-[22px]">
            {question.question_subtitle}
          </p>
        ) : null}

        <div className="mb-3" />

        {(question.choices || []).length > 0 ? (
          <ul
            className="flex flex-wrap gap-1"
            data-testid={`question-choices-${questionIndex}`}
          >
            {(question.choices || []).map((choice, choiceIndex) => {
              return (
                <li key={choiceIndex}>
                  <Toggle
                    className={cn(
                      "border-blueBorder text-bodylg text-textLink hover:bg-blueBgLight hover:text-textLink data-[state=on]:border-textLink data-[state=on]:bg-textLink data-[state=on]:text-textWhite h-auto rounded-lg border-2 py-0.5 pl-2 pr-2",
                      choice.color && colorMap[choice.color],
                    )}
                    data-testid={`question-choice-${choiceIndex}`}
                    disabled={submitting || showLoading || disabled}
                    onPressedChange={(pressed) => {
                      if (question.allow_multiple) {
                        setSelectedChoices((prev) => {
                          if (pressed) {
                            return {
                              ...prev,
                              [questionIndex]: Array.from(
                                new Set([...prev[questionIndex], choiceIndex]),
                              ),
                            };
                          } else {
                            return {
                              ...prev,
                              [questionIndex]: prev[questionIndex].filter(
                                (ci) => ci !== choiceIndex,
                              ),
                            };
                          }
                        });
                      } else {
                        setSelectedChoices((prev) => {
                          if (pressed) {
                            return {
                              ...prev,
                              [questionIndex]: [choiceIndex],
                            };
                          } else {
                            return { ...prev, [questionIndex]: [] };
                          }
                        });
                      }
                    }}
                    pressed={selectedChoices[questionIndex].includes(
                      choiceIndex,
                    )}
                  >
                    {question.allow_multiple &&
                    selectedChoices[questionIndex].includes(choiceIndex) ? (
                      <Check className="mr-1 h-4 w-4" />
                    ) : null}
                    {question.allow_multiple &&
                    !selectedChoices[questionIndex].includes(choiceIndex) ? (
                      <Plus className="mr-1 h-4 w-4" />
                    ) : null}

                    {choice.icon ? (
                      <div className="mr-1 h-4 w-4">{iconMap[choice.icon]}</div>
                    ) : null}
                    {sentenceCase(choice.label)}
                  </Toggle>
                </li>
              );
            })}
          </ul>
        ) : null}

        {shouldRenderTextField && (question.text_field_lines ?? 1) > 1 ? (
          <Textarea
            className="mt-3 text-lg leading-[22px] placeholder:text-lg placeholder:leading-[22px]"
            data-testid={`question-free-text-${questionIndex}`}
            disabled={submitting || showLoading || disabled}
            onChange={(event) => {
              setFreeText(event.target.value);
            }}
            placeholder={question.text_field_placeholder}
            value={freeText}
          />
        ) : null}

        {shouldRenderTextField && (question.text_field_lines ?? 1) === 1 ? (
          <Input
            className="mt-3 text-lg leading-[22px] placeholder:text-lg placeholder:leading-[22px]"
            data-testid={`question-free-text-${questionIndex}`}
            disabled={submitting || showLoading || disabled}
            onChange={(event) => {
              setFreeText(event.target.value);
            }}
            placeholder={question.text_field_placeholder}
            type="text"
            value={freeText}
          />
        ) : null}
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <>
        <Button
          className="wizard-step-hide"
          data-testid="next-button"
          disabled={
            submitting ||
            showLoading ||
            disabled ||
            (questionData.required &&
              !Object.entries(selectedChoices).some(
                ([_, value]) => value.length,
              ) &&
              !freeText)
          }
          onClick={handleClickNext}
          size="sm"
        >
          {submitting || showLoading ? (
            <>
              <Loader2 className="mr-3 animate-spin" data-testid="submitting" />
            </>
          ) : null}
          {questionData.next_button_label || "Next"}
          <ArrowRight className="ml-2" size={16} />
        </Button>
      </>
    );
  };

  return (
    <div
      className={cn(
        "wizard-step-transition flex h-full w-full justify-center gap-12",
        shouldShowMap && shouldRenderSideBySide ? "max-w-[1408px]" : "",
      )}
    >
      {shouldShowMap && shouldRenderSideBySide ? (
        <div
          className={cn(
            "flex w-full",
            shouldShowMap && shouldRenderSideBySide
              ? "h-full items-center"
              : "",
          )}
        >
          {renderMap()}
        </div>
      ) : null}

      <div
        className={cn(
          "flex w-full grow flex-col md:max-w-[680px]",
          shouldRenderSideBySide ? "justify-center" : "",
        )}
        data-testid="step-container-multi-select"
      >
        <div
          className={cn(
            "flex w-full flex-col",
            shouldRenderSideBySide ? "h-fit" : "h-full",
          )}
        >
          <h2 className="text-textPrimary text-[24px] font-semibold leading-[32px]">
            {questionData.question_title}
          </h2>

          {questionData.question_subtitle ? (
            <p className="text-textSubdued text-base leading-[20px]">
              {questionData.question_subtitle}
            </p>
          ) : null}

          <div className="mb-3" />

          {shouldShowMap && !shouldRenderSideBySide ? renderMap() : null}

          {questions.length ? (
            <div className="flex flex-col gap-3 md:gap-6">
              {questions.map(renderQuestion)}
            </div>
          ) : null}

          {!shouldRenderSideBySide ? <div className="flex-grow" /> : null}

          <div className="mt-3 flex justify-end pb-6 md:mt-8 md:pb-12">
            {renderFooter()}
          </div>
        </div>
      </div>

      {submitting || showLoading || disabled ? <LoadingOverlay /> : null}
    </div>
  );
}
