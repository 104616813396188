import { type Message } from "shared/apiClient";
import { type Noticeboard } from "shared/lib";
import {
  getReportLoadingProgressIndicatorItems,
  reportLoadingProgressIndicatorItemsMap,
  type ProgressIndicatorItemMap,
} from "./lib";

import { ReportLoader } from "./ReportLoader";

export interface ReportLoaderContainerProps {
  className?: string;
  latestUserMessage?: Message;
  noticeboardData: Noticeboard;
  progressIndicatorItemMap?: ProgressIndicatorItemMap;
}

export function ReportLoaderContainer({
  className,
  latestUserMessage,
  noticeboardData,
  progressIndicatorItemMap = reportLoadingProgressIndicatorItemsMap,
}: ReportLoaderContainerProps) {
  return (
    <ReportLoader
      className={className}
      items={getReportLoadingProgressIndicatorItems(
        noticeboardData,
        parseInt(latestUserMessage?.message_number || "-999"),
        progressIndicatorItemMap,
      )}
    />
  );
}
