import { CogIcon } from "@/components/icons";
import {
  ProgressIndicatorList,
  type ProgressIndicatorListProps,
} from "shared/components";

export interface ReportLoaderProps {
  className?: string;
  items: ProgressIndicatorListProps["items"];
}

export function ReportLoader({ className, items }: ReportLoaderProps) {
  return (
    <div className={className} data-testid="report-loading-indicator">
      <div className="border-interfaceBorder bg-interfaceBgLight text-textSubdued content-start rounded-t-lg border px-10 py-8">
        <h2 className="text-textPrimary mb-1 flex flex-row text-[28px] font-semibold leading-8">
          <CogIcon className="mr-2 h-7 w-7 animate-spin [animation-duration:2s]" />
          Generating report
        </h2>
        <p className="text-textPrimary text-lg leading-[22px]">
          This typically takes about 90 seconds.
        </p>
      </div>
      <div className="border-interfaceBorder bg-interfaceWhite rounded-b-lg border-x border-b px-10 py-8">
        <p className="text-textSubdued text-lg leading-[22px]">
          The report will appear here when it has finished generating.
        </p>

        <div className="mt-[24px]">
          <ProgressIndicatorList items={items} />
        </div>
      </div>
    </div>
  );
}
