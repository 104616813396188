import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

const REACT_ENV = import.meta.env.VITE_REACT_ENV;
const isLocal = import.meta.env.VITE_REACT_LOCAL === "true";

if (!isLocal) {
  Sentry.init({
    dsn: "https://c3a2685cd01f8e82b435fe6e9668db80@o4507858230902784.ingest.de.sentry.io/4508255372181584",
    environment: REACT_ENV,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
