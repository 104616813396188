import { useMemo } from "react";
import { useAtomValue } from "jotai";

import { AccountType } from "shared/types";
import { noticeboardDataVar } from "@/variables/globalVar";

import { useExpertMapConfig } from "shared/hooks";
import { useAccount, useLatestMessage } from "@/hooks";

export function useIsReportSummaryStale() {
  const { conversation } = useAccount();

  const expertMapConfig = useExpertMapConfig(conversation);
  const expertMap = expertMapConfig?.["enquiry"];

  const latestUserMessage = useLatestMessage({
    senderAccountType: AccountType.USER,
  });

  const noticeboardData = useAtomValue(noticeboardDataVar);

  return useMemo(() => {
    const { expert_name } = expertMap?.report_summary || {};
    const reportSummary = noticeboardData?.[expert_name];
    if (reportSummary && latestUserMessage?.message_number) {
      return (
        reportSummary.message_number !==
        parseInt(latestUserMessage.message_number)
      );
    }
    return false;
  }, [expertMap, latestUserMessage, noticeboardData]);
}
