import { Loader2 } from "lucide-react";

import { Button, DialogTitle, DialogFooter } from "shared/components";

export interface InProgressContentProps {
  onClickCancel: () => void;
  onClickSubmit: () => void;
  submitting: boolean;
}

export function InProgressContent({
  onClickCancel,
  onClickSubmit,
  submitting,
}: InProgressContentProps) {
  return (
    <>
      <DialogTitle>Start again?</DialogTitle>
      <div className="text-textPrimary flex flex-col gap-4">
        <p className="text-bodylg ">
          If you restart, you will lose all the responses you’ve entered.
        </p>
        <div className="border-interfaceBorder bg-interfaceBgDefault rounded-sm border-l-4 px-4 py-3">
          <h2 className="text-headingmd font-semibold mb-1">
            ☝ Want to adjust your proposal or change a response?
          </h2>
          <p className="text-bodymd">
            ️Instead of restarting, answer a few more questions. You’ll have an
            opportunity to adjust your proposal once all questions have been
            answered and your proposal has been analysed.
          </p>
        </div>
      </div>
      <DialogFooter>
        <Button onClick={onClickCancel} variant="outline">
          Cancel
        </Button>
        <Button className="gap-2" onClick={onClickSubmit} variant="default">
          {submitting && (
            <>
              <Loader2 className="animate-spin" />{" "}
            </>
          )}
          Discard and restart
        </Button>
      </DialogFooter>
    </>
  );
}
