import { X } from "lucide-react";
import { Loader2 } from "lucide-react";

import { Button, Dialog, DialogContent, DialogClose } from "shared/components";

export interface SubmitReportForReviewDialogProps {
  email: string;
  isSubmitting: boolean;
  onClickSubmit: () => void;
  onOpenChange: (open: boolean) => void;
  open: boolean;
}

export function SubmitReportForReviewDialog({
  email,
  isSubmitting,
  onClickSubmit,
  onOpenChange,
  open,
}: SubmitReportForReviewDialogProps) {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent
        className="max-w-[520px] gap-0 rounded-3xl border-0 p-0"
        hideCloseButton
      >
        <DialogClose
          aria-label="Close"
          className="absolute end-[16px] top-[16px]"
          role="button"
        >
          <X className="text-textPrimary h-[24px] w-[24px]" />
        </DialogClose>
        <div className="bg-interfaceWhite flex flex-col rounded-[20px] px-[60px] py-[48px] shadow-sm">
          <h2 className="text-textPrimary flex items-center gap-2 text-[28px] font-semibold leading-[1.14]">
            Submit for review
          </h2>
          <div className="mt-[20px] flex flex-col gap-[8px]">
            <p className="text-textPrimary text-lg leading-[1.2222]">
              If you’re happy with your proposal and how it’s been interpreted,
              submit it for review.
            </p>
            <p className="text-textPrimary text-lg leading-[1.2222]">
              Once the report has been reviewed, you’ll receive a shareable link
              to it by email (<span className="font-semibold">{email}</span>
              ).
            </p>
          </div>
          <div className="mt-[32px] flex flex-col gap-[12px]">
            <div className="flex justify-end gap-[12px]">
              <Button
                className="border-blue-600 text-blue-600"
                onClick={() => onOpenChange(false)}
                variant="outline"
              >
                Cancel
              </Button>
              <Button
                className="bg-blue-600 text-white"
                onClick={onClickSubmit}
              >
                {isSubmitting && (
                  <>
                    <Loader2 className="animate-spin" />{" "}
                  </>
                )}
                Submit for review
              </Button>
            </div>
            <p className="text-textPrimary text-right text-sm">
              ☝️ You won’t be able to adjust your proposal once it’s submitted
              for review.
            </p>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
