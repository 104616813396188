import { useEffect, useMemo, useState } from "react";
import { Check, Loader2, TriangleAlert, ArrowRight } from "lucide-react";

import { type Message } from "shared/apiClient";
import type { QuestionData } from "@/components/wizard/lib";
import { cn } from "shared/lib";

import { createMessageEvent, useChannel } from "shared/channel";
import { useMediaQuery } from "shared/hooks";
import { useAccount } from "@/hooks";

import { Button, Separator, Slider } from "shared/components";
import { LoadingOverlay } from "@/components/wizard/widgets/LoadingOverlay";

import tpzDiagram from "@/assets/tpz-diagram.png";

export interface TreeDistanceStepProps {
  disabled?: boolean;
  message: Message;
  showLoading?: boolean;
}

export function TreeDistanceStep({
  disabled,
  message,
  showLoading,
}: TreeDistanceStepProps) {
  const questionData = message.question_data as QuestionData;

  const { channel } = useChannel();

  const { conversation } = useAccount();

  const [submitting, setSubmitting] = useState(false);
  const [treeDiameter, setTreeDiameter] = useState(0.3);
  const [treeDistance, setTreeDistance] = useState(5);

  const shouldRenderSideBySide = useMediaQuery({
    query: "(min-width: 822px)",
  });

  const protectionZoneDistance = useMemo(() => {
    return treeDiameter * 12;
  }, [treeDiameter]);

  useEffect(() => {
    const diameter = window.localStorage.getItem(
      `${message.conversation_id}.treeDiameter`,
    );
    setTreeDiameter(diameter ? Number(diameter) : 0.3);
  }, [message]);

  const handleClickNext = () => {
    if (channel && conversation?.id) {
      setSubmitting(true);

      const text = `The tree is ${treeDistance.toFixed(1)} meters away from the proposed works.`;

      const messageDescriptor = createMessageEvent({
        conversation_id: conversation.id,
        text,
      });

      console.log("<TreeDistanceStep> handleClickNext()", messageDescriptor);
      channel.sendMessage(messageDescriptor);
    }
  };

  const renderDiagram = () => {
    return (
      <img src={tpzDiagram} width={120} className="h-auto" alt="Tpz diagram" />
    );
  };

  const renderDescription = () => {
    return (
      <>
        <p className="text-textSubdued text-lg leading-[22px]">
          Based on the{" "}
          <b>
            tree’s diameter of breast height of {treeDiameter.toFixed(1)} metres
          </b>
          , we’ve calculated a{" "}
          <b>
            protection zone around the tree of about{" "}
            {protectionZoneDistance.toFixed(1)} metres
          </b>
          .
        </p>
        <p className="text-textSubdued text-lg leading-[22px]">
          If your proposal encroaches on the protection zone, the tree’s root
          system could be at risk during construction, and more planning
          requirements will likely apply to your proposal.
        </p>
      </>
    );
  };

  const renderFooter = () => {
    return (
      <>
        <Button
          className="wizard-step-hide"
          data-testid="next-button"
          disabled={submitting || showLoading || disabled}
          onClick={handleClickNext}
        >
          {submitting || showLoading ? (
            <>
              <Loader2 className="mr-3 animate-spin" data-testid="submitting" />
            </>
          ) : null}
          {questionData.next_button_label || "Next"}
          <ArrowRight className="ml-2" size={16} />
        </Button>
      </>
    );
  };

  return (
    <div
      className={cn(
        "wizard-step-transition flex h-full w-full justify-center gap-12",
        shouldRenderSideBySide ? "max-w-[822px] items-center" : "",
      )}
      data-testid="step-container-tree-distance"
    >
      {shouldRenderSideBySide ? (
        <div className="bg-interfaceBgDefault flex min-h-[401px] min-w-[342px] items-center justify-center rounded-xl p-6">
          <div className="inline-block max-h-[353px] max-w-[294px]">
            {renderDiagram()}
          </div>
        </div>
      ) : null}

      <div
        className={cn(
          "flex w-full flex-col gap-4",
          shouldRenderSideBySide ? "max-w-[440px]" : "",
        )}
      >
        <h2 className="text-textPrimary mb-2 text-[24px] font-semibold leading-[28px]">
          {questionData.question_title}
        </h2>

        {shouldRenderSideBySide ? renderDescription() : null}

        <div className="flex flex-col">
          <div className="flex items-center gap-2 pb-3">
            <Slider
              className=""
              defaultValue={[8]}
              disabled={submitting || showLoading || disabled}
              max={15}
              min={1}
              onValueChange={(value) => {
                setTreeDistance(value[0]);
              }}
              step={1}
              value={[treeDistance]}
            />
            <div className="w-[20px]">{treeDistance}m</div>
          </div>
          {treeDistance >= protectionZoneDistance ? (
            <div className="sh-bg-successBgDefault text-successText flex gap-1 rounded px-2 py-1">
              <Check />
              Outside tree protection zone
            </div>
          ) : (
            <div className="bg-warningBgDefault text-warningText flex gap-1 rounded px-2 py-1">
              <TriangleAlert />
              Within tree protection zone
            </div>
          )}
        </div>

        {!shouldRenderSideBySide ? (
          <>
            <Separator orientation="horizontal" />
            <div className="flex gap-3">
              <div className="flex min-w-[120px] flex-col">
                {renderDiagram()}
              </div>
              <div className="grow">{renderDescription()}</div>
            </div>
            <div className="flex-grow" />
          </>
        ) : null}

        <div className="mt-3 flex justify-end pb-6 md:pb-12">
          {renderFooter()}
        </div>
      </div>

      {submitting || showLoading || disabled ? <LoadingOverlay /> : null}
    </div>
  );
}
