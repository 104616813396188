import { Loader2 } from "lucide-react";

import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  Separator,
} from "shared/components";
import { useAccount } from "@/hooks";
import { useCouncilLocalisation } from "shared/hooks";

export interface ReportDoneDialogProps {
  onOpenChange: (open: boolean) => void;
  open: boolean;
}

export function ReportDoneDialog({
  onOpenChange,
  open,
}: ReportDoneDialogProps) {
  const { account, conversation } = useAccount();
  const { getLocalisationEntry } = useCouncilLocalisation(conversation);

  const userEmail = account?.email;

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent
        aria-describedby={undefined}
        className="flex max-h-[80%] flex-col gap-5 p-6 md:p-8"
      >
        <DialogTitle>✅ All done!</DialogTitle>

        <p className="text-bodylg text-textPrimary mb-2">
          We’ll also send a copy of your proposal’s analysis to{" "}
          <b>{userEmail}</b> after it’s been processed.
        </p>
        <Separator orientation="horizontal" />
        <p className="text-bodylg text-textPrimary">
          Want to chat to us about your proposal’s analysis?{" "}
          <a
            className="text-textLink underline"
            href={getLocalisationEntry("councilContactLink")}
            rel="noreferrer"
            target="_blank"
            tabIndex={0}
          >
            Contact Council
          </a>{" "}
          to speak to one of our planners. When you contact us, tell us your
          session ID:
          <span className="border-interactiveBorder text-textPrimary mt-[8px] block w-min rounded border px-2 py-1.5 text-lg leading-4 tracking-[2.7px] md:text-nowrap">
            {conversation?.identifier?.toUpperCase()}
          </span>
        </p>

        <DialogFooter className="flex justify-end gap-3">
          <Button onClick={() => onOpenChange(false)} variant="outline">
            Close
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
