import { LotPanel } from "@/components/lot";
import { ProposalPanel } from "@/components/proposal";

export function LotAndProposalTab() {
  return (
    <div className="flex h-full flex-col gap-6 overflow-y-auto p-6">
      <LotPanel />

      <ProposalPanel />
    </div>
  );
}
