import { useEffect } from "react";
import { useAtom, useAtomValue } from "jotai";
import { Loader } from "lucide-react";

import { chatLockVar, isLoadingVar } from "@/variables/globalVar";

import { createMessageEvent, useChannel } from "shared/channel";
import { useMediaQuery } from "shared/hooks";
import { useAccount, useTracking } from "@/hooks";

import { ChatList } from "./ChatList";
import { PromptForm } from "./PromptForm";

export function Chat() {
  const { channel } = useChannel();

  const { conversation } = useAccount();

  const [isLoading, setIsLoadingVar] = useAtom(isLoadingVar);
  const isChatLocked = useAtomValue(chatLockVar);

  const { stateChange } = useTracking("<Chat>");

  const md = useMediaQuery({
    query: "(min-width: 768px)",
  });

  useEffect(() => {
    if (!md) {
      stateChange("/chat");
    }
  }, [stateChange]); // eslint-disable-line

  const handleSubmit = async (value: string) => {
    if (channel && conversation?.id) {
      const messageDescriptor = createMessageEvent({
        conversation_id: conversation.id,
        text: value,
      });
      console.log("<Chat> handleSubmit()", messageDescriptor);
      channel.sendMessage(messageDescriptor);
    }
    setIsLoadingVar(true);
  };

  return (
    <section
      aria-label="chat"
      className="flex h-full min-h-0 flex-col"
      role="region"
    >
      {!conversation ? (
        <div className="flex h-full w-full items-center justify-center">
          <Loader className="text-textSubdued h-[48px] w-[48px] animate-spin" />
        </div>
      ) : (
        <>
          <ChatList />

          <div>
            <div className="bg-interfaceWhite h-full self-end">
              <div className="w-full">
                <PromptForm
                  conversation={conversation}
                  isChatLocked={
                    isChatLocked || (conversation?.messages || []).length === 0
                  }
                  isLoading={isLoading}
                  onSubmit={handleSubmit}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  );
}
