import { useMemo } from "react";
import { useAtomValue } from "jotai";

import {
  accountVar,
  conversationOverrideVar,
  // panelFlowTypeVar,
} from "@/variables/globalVar";

export function useAccount() {
  const account = useAtomValue(accountVar);
  const conversationOverride = useAtomValue(conversationOverrideVar);
  // const panelFlowType = useAtomValue(panelFlowTypeVar);

  // const application = useMemo(() => {
  //   return account?.current_applications?.find((application) => {
  //     return application.panel_flow_type === panelFlowType;
  //   });
  // }, [account?.current_applications, panelFlowType]);

  const application = useMemo(() => {
    return account?.current_application;
  }, [account?.current_application]);

  const conversation = useMemo(() => {
    if (conversationOverride) {
      return conversationOverride;
    }
    return application?.current_conversation;
  }, [application?.current_conversation, conversationOverride]);

  return { account, application, conversation };
}
