import { useEffect } from "react";

interface CustomTitleProps {
  councilName: string;
}

export function CustomTitle({ councilName }: CustomTitleProps) {
  useEffect(() => {
    document.title = `Planning Enquiry Tool | ${councilName}`;
  }, [councilName]);

  return null;
}
