import { type Noticeboard } from "shared/lib";

import { ProgressIndicatorListStatus } from "shared/components";

export type ProgressIndicatorItem = {
  status: ProgressIndicatorListStatus;
  text: string;
  icon: string;
};

export type ProgressIndicatorItemMap = {
  [expertName: string]: ProgressIndicatorItem;
};

export const reportLoadingProgressIndicatorItemsMap: ProgressIndicatorItemMap =
  {
    land_use_terms: {
      status: ProgressIndicatorListStatus.none,
      text: "Assessing the land use against your zone requirements",
      icon: "🔍",
    },
    // Overlay_Assessor_Andrew: {
    //   status: ProgressIndicatorListStatus.none,
    //   text: "Reviewing buildings and works conditions against overlay legislation",
    //   icon: "🚧",
    // },
    bmo_overlay_assessor: {
      status: ProgressIndicatorListStatus.none,
      text: "Reviewing buildings and works conditions against overlay legislation",
      icon: "🚧",
    },
    land_use_assessor: {
      status: ProgressIndicatorListStatus.none,
      text: "Evaluating potential impacts to vegetation",
      icon: "🌳",
    },
    // sign_assessor: {
    //   status: ProgressIndicatorListStatus.none,
    //   text: "Checking for signage requirements",
    //   icon: "🪧",
    // },
    report_summary_legalese: {
      status: ProgressIndicatorListStatus.none,
      text: "Summarising findings",
      icon: "📝",
    },
  };

export function getReportLoadingProgressIndicatorItems(
  noticeboardData: Noticeboard,
  latestUserMessageNumber: number,
  expertNamesMap = reportLoadingProgressIndicatorItemsMap,
) {
  const expertNames = Object.keys(expertNamesMap);

  const expertsStatuses = expertNames.reduce(
    (statuses, expertName) => {
      if (
        noticeboardData[expertName]?.message_number === latestUserMessageNumber
      ) {
        statuses[expertName] = ProgressIndicatorListStatus.done;
      } else {
        statuses[expertName] = ProgressIndicatorListStatus.none;
      }
      return statuses;
    },
    {} as { [expertName: string]: ProgressIndicatorListStatus },
  );

  return expertNames.map((expertName, index) => {
    const item = { ...expertNamesMap[expertName] };

    if (index === 0) {
      item.status =
        expertsStatuses[expertName] === ProgressIndicatorListStatus.done
          ? ProgressIndicatorListStatus.done
          : ProgressIndicatorListStatus.loading;
    } else if (
      expertsStatuses[expertName] === ProgressIndicatorListStatus.done
    ) {
      item.status = ProgressIndicatorListStatus.done;
    } else if (
      expertsStatuses[expertNames[index - 1]] ===
      ProgressIndicatorListStatus.done
    ) {
      item.status = ProgressIndicatorListStatus.loading;
    } else {
      item.status = ProgressIndicatorListStatus.none;
    }

    return item;
  });
}
