import { useAtomValue, useSetAtom } from "jotai";
import { useQueryClient } from "@tanstack/react-query";

import { ConversationPhase } from "shared/types";
import {
  isConversationInProgress,
  isConversationDraft,
  isConversationDone,
} from "shared/lib";
import { EventTypesEnum } from "@/types/events";
import {
  applicationPageVar,
  messagesVar,
  Pages,
  panelFlowTypeVar,
  Sections,
  wizardNavigatorVar,
} from "@/variables/globalVar";

import { getAccountQueryKey, useCreateApplication } from "shared/apiHooks";
import { useAccount } from "@/hooks";

import { DialogContent } from "shared/components";
import { InProgressContent } from "./InProgressContent";
import { ReportReadyContent } from "./ReportReadyContent";
import { ReportDoneContent } from "./ReportDoneContent";

export interface RestartDialogProps {
  conversationPhase: ConversationPhase;
  onOpenChange: (open: boolean) => void;
}

export function RestartDialog({
  conversationPhase,
  onOpenChange,
}: RestartDialogProps) {
  const queryClient = useQueryClient();

  const panelFlowType = useAtomValue(panelFlowTypeVar);
  const setWizardNavigatorVar = useSetAtom(wizardNavigatorVar);
  const setApplicationPageVar = useSetAtom(applicationPageVar);
  const setMessagesVar = useSetAtom(messagesVar);

  const { account } = useAccount();
  const userEmail = account?.email;

  const { isPending: createApplicationLoading, mutate: createApplication } =
    useCreateApplication({
      onError: (error) => {
        console.error("<RestartDialog> createApplication() onError()", error);
      },
      onSuccess: (response) => {
        console.log(
          "<RestartDialog> createApplication() onSuccess",
          response.data,
        );
        onOpenChange(false);
        setMessagesVar([]);
        setApplicationPageVar(Pages.Address);
        setWizardNavigatorVar(Sections.Address);
        queryClient.invalidateQueries({ queryKey: getAccountQueryKey() });
      },
    });

  const handleClickRestart = () => {
    console.log("<RestartDialog> handleClickRestart()");
    const abortCreateApplication = new AbortController();
    createApplication({
      body: { panel_flow_type: panelFlowType },
      signal: abortCreateApplication.signal,
    });
  };

  const handleClickAdjustPlans = () => {
    onOpenChange(false);
    const adjustPlansEvent = new CustomEvent(EventTypesEnum.AdjustPlans);
    document.dispatchEvent(adjustPlansEvent);
  };

  const handleClickSaveReport = () => {
    onOpenChange(false);
    const savePlansEvent = new CustomEvent(EventTypesEnum.SaveReport);
    document.dispatchEvent(savePlansEvent);
  };

  return (
    <DialogContent aria-describedby={undefined} className="max-w-[600px] gap-4">
      {isConversationInProgress(conversationPhase) ? (
        <InProgressContent
          onClickCancel={() => onOpenChange(false)}
          onClickSubmit={handleClickRestart}
          submitting={createApplicationLoading}
        />
      ) : null}

      {isConversationDraft(conversationPhase) ? (
        <ReportReadyContent
          email={userEmail || ""}
          onClickCancel={() => onOpenChange(false)}
          onClickAdjustProposal={handleClickAdjustPlans}
          onClickMarkAsDone={handleClickSaveReport}
          onClickSubmit={handleClickRestart}
          submitting={createApplicationLoading}
        />
      ) : null}

      {isConversationDone(conversationPhase) ? (
        <ReportDoneContent
          onClickCancel={() => onOpenChange(false)}
          onClickSubmit={handleClickRestart}
          submitting={createApplicationLoading}
        />
      ) : null}
    </DialogContent>
  );
}
