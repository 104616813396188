import { useRef, useState, type FormEvent } from "react";
import { useSetAtom } from "jotai";
import { ChevronLeft } from "lucide-react";

import { AuthFlow, authFlowSelectedVar } from "@/variables/globalVar";

import { usePasswordReset } from "shared/apiHooks";

import { Input } from "shared/components";
import { IconSpinner } from "@/components/icons";

export function ForgotPassword() {
  const setAuthFlowSelectedVar = useSetAtom(authFlowSelectedVar);

  const emailRef = useRef<HTMLInputElement>(null);

  const [errorMsg, setErrorMsg] = useState<string>("");

  const {
    isPending: passwordResetLoading,
    isSuccess: passwordResetSuccess,
    mutate: passwordReset,
  } = usePasswordReset({
    onError(error) {
      console.error("<AuthPage> usePasswordReset() onError()", error);
      const response = error.response;
      if (response?.data && Array.isArray(response.data["non_field_errors"])) {
        setErrorMsg(response.data["non_field_errors"][0]);
      } else {
        setErrorMsg(
          "There was an unexpected error requesting a password reset. Please refresh your browser and try again, or contact support for assistance.",
        );
      }
    },
    onSuccess() {
      console.log("<AuthPage> usePasswordReset() onSuccess()");
    },
  });

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!emailRef.current?.value) {
      setErrorMsg("Email field must not be blank");
      return;
    }

    setErrorMsg("");
    passwordReset({ body: { email: emailRef.current?.value } });
  };

  return (
    <div className="flex flex-col p-6">
      <button
        onClick={() => {
          setErrorMsg("");
          setAuthFlowSelectedVar(AuthFlow.Login);
        }}
        className="text-textLink hover:text-textLinkHover mb-1 flex w-fit flex-row items-center text-base leading-5 underline"
      >
        <ChevronLeft className="mr-0.5 h-4 w-4 p-[1px]" />
        Log in
      </button>
      <h1 className="mb-2 text-left text-[32px] font-semibold leading-10">
        {!passwordResetSuccess ? "Forgot your password?" : "Thank you"}
      </h1>
      <p
        hidden={passwordResetSuccess}
        className="mb-6 text-left text-base leading-5"
      >
        {
          "Enter your email address and we’ll send you a link to reset your password."
        }
      </p>
      <p
        hidden={!passwordResetSuccess}
        className="mb-6 text-left text-base leading-5"
      >
        We’ve emailed a link to{" "}
        <span className="font-semibold">{emailRef.current?.value}</span> to
        reset your password.
      </p>
      {!passwordResetSuccess && (
        <div className="flex">
          <form onSubmit={handleSubmit} className="w-full">
            <div className="flex flex-col gap-y-3">
              <Input
                autoCapitalize="none"
                autoComplete="email"
                autoCorrect="off"
                className="border-interactiveBorder bg-interfaceWhite placeholder:text-textSubdued text-textPrimary rounded-lg p-4 text-base leading-5"
                disabled={passwordResetLoading}
                id="email"
                placeholder="Email address"
                ref={emailRef}
                type="email"
              />
            </div>
            <button
              className="bg-actionDefault text-textWhite mt-4 flex w-full flex-row items-center justify-center rounded px-4 py-2 text-lg font-semibold leading-[22px]"
              disabled={passwordResetLoading}
            >
              Reset password
              {passwordResetLoading && (
                <IconSpinner className="ml-1 h-4 w-4 animate-spin" />
              )}
            </button>
            <div
              hidden={!errorMsg}
              className="border-destructiveBorder bg-destructiveBgDefault text-destructiveText mt-6 rounded border px-4 py-2 text-center text-base"
            >
              {errorMsg}
            </div>
          </form>
        </div>
      )}
    </div>
  );
}
