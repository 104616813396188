import { ComponentProps } from "react";

export function TreeSmall({ className, style }: ComponentProps<"svg">) {
  return (
    <svg
      width="63"
      height="114"
      viewBox="0 0 63 114"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <g clipPath="url(#clip0_6995_5143)">
        <path
          d="M1.15008 39.8806C4.26008 39.7306 -0.229919 34.3906 1.50008 35.0006C3.23008 35.6106 7.58008 34.6806 9.19008 33.0006C10.8001 31.3206 12.4301 34.6106 14.5101 33.4606C16.5901 32.3106 20.1601 30.8106 21.5101 32.9706C22.8601 35.1306 23.8701 36.1206 25.5101 36.3406C27.1501 36.5606 22.9801 38.8406 22.6801 40.7906C22.3801 42.7406 22.8101 47.6106 21.0301 46.7906C19.2501 45.9706 19.8401 48.3306 18.5001 49.2906C17.1601 50.2506 16.2801 46.7306 14.2001 47.8706C12.1201 49.0106 12.3601 53.4606 10.6901 51.3106C9.02008 49.1606 6.94008 47.1906 6.49008 48.5506C6.04008 49.9106 3.17008 48.0006 2.67008 46.0006C2.17008 44.0006 -1.96992 40.0006 1.15008 39.8806Z"
          fill="#71956D"
        />
        <path
          d="M45.9001 19.2401C42.4801 18.0301 44.9901 26.5301 43.3901 25.0001C41.7901 23.4701 36.7301 22.6501 34.2401 24.0001C31.7501 25.3501 31.4501 20.5901 28.7201 21.0001C25.9901 21.4101 21.5001 21.6801 21.0101 18.4601C20.5201 15.2401 19.8301 13.5701 18.1701 12.5801C16.5101 11.5901 21.9801 10.6801 23.1701 8.46014C24.3601 6.24014 26.0001 0.120144 27.5601 1.90014C29.1201 3.68014 29.5601 0.560129 31.3701 0.0101295C33.1801 -0.539871 32.6401 4.1101 35.3701 3.6701C38.1001 3.2301 39.7801 -2.32986 40.6501 1.08014C41.5201 4.49014 42.8901 7.77013 43.9601 6.32013C45.0301 4.87013 47.2601 8.49012 46.9601 11.1301C46.6601 13.7701 49.3501 20.4601 45.9001 19.2401Z"
          fill="#71956D"
        />
        <path
          d="M53.4301 37.9105C51.2801 37.1405 52.8601 42.5005 51.8501 41.5305C51.0072 40.9753 50.0438 40.6294 49.0402 40.5215C48.0366 40.4137 47.0218 40.547 46.0801 40.9105C44.5101 41.7705 44.3201 38.7605 42.6001 39.0405C40.8801 39.3205 38.0401 39.4705 37.7301 37.4405C37.4201 35.4105 36.9901 34.3505 35.9501 33.7205C34.9101 33.0905 38.3501 32.5305 39.0801 31.1305C39.8101 29.7305 40.8701 25.8705 41.8501 26.9905C42.8301 28.1105 43.0801 26.1505 44.2501 25.8005C45.4201 25.4505 45.0501 28.3905 46.7801 28.1105C48.5101 27.8305 49.5601 24.3605 50.1101 26.4705C50.6601 28.5805 51.5201 30.6905 52.2001 29.7805C52.8801 28.8705 54.2801 31.1505 54.0701 32.7805C53.8601 34.4105 55.5901 38.6705 53.4301 37.9105Z"
          fill="#71956D"
        />
        <path
          d="M19.8 34.0802C17.64 33.3202 19.22 38.6802 18.22 37.7102C17.3752 37.1549 16.4102 36.809 15.405 36.7012C14.3998 36.5933 13.3834 36.7267 12.44 37.0902C10.87 37.9502 10.69 34.9402 8.96004 35.2202C7.23004 35.5002 4.41004 35.6402 4.10004 33.6102C3.79004 31.5802 3.35004 30.5302 2.35004 29.9002C1.35004 29.2702 4.75004 28.7102 5.49004 27.3102C6.23004 25.9102 7.27005 22.0502 8.26005 23.1702C9.25005 24.2902 9.49004 22.3302 10.66 21.9802C11.83 21.6302 11.46 24.5702 13.18 24.2802C14.9 23.9902 15.96 20.5302 16.51 22.6502C17.06 24.7702 17.93 26.8702 18.6 25.9602C19.27 25.0502 20.68 27.3302 20.47 28.9602C20.26 30.5902 21.95 34.8502 19.8 34.0802Z"
          fill="#71956D"
        />
        <path
          d="M31.46 112.371C31.46 112.371 29.17 94.0005 29.17 88.6805C29.17 83.3605 28.4 69.2206 29.17 66.3306C29.94 63.4406 36.81 46.6305 38.34 42.0505L39.87 37.4606C39.87 37.4606 44.46 29.0506 46.75 26.7606C49.04 24.4706 46.75 23.3605 46.75 23.3605L43.69 27.5206L38.47 34.5906L36.05 41.2806L35.28 33.6406L36.81 16.0605L33.75 17.5906C33.75 17.5906 32.99 25.2305 32.99 29.0505C32.99 32.8705 33.75 42.0506 32.99 44.3406C32.23 46.6306 32.23 55.0406 30.7 56.5706C29.17 58.1006 26.87 61.9206 25.35 59.6306C23.8082 57.1566 22.0117 54.8507 19.99 52.7505C17.7 50.4605 18.47 48.9306 17.7 46.6306C16.5352 42.5963 16.5352 38.3148 17.7 34.2806L16.17 32.8705C16.17 32.8705 16.94 34.7505 16.17 35.7205C15.4 36.6905 14.64 32.1105 14.64 32.1105L12.35 29.8206V33.4205C12.8505 33.8035 13.251 34.3018 13.5174 34.8729C13.7838 35.444 13.9082 36.071 13.88 36.7006C14.0974 38.7995 14.6126 40.8568 15.41 42.8105C15.41 42.8105 15.41 45.1106 13.88 44.3406C12.35 43.5706 10.06 39.7505 10.06 39.7505L7.76001 41.2806L10.82 43.5806C10.82 43.5806 15.41 48.9305 16.17 51.2205C16.93 53.5105 20.76 55.8105 21.52 58.1005C22.28 60.3905 25.35 68.8005 24.58 75.6805C23.81 82.5605 26.11 81.0305 25.35 84.8605C24.59 88.6905 25.35 91.7405 24.58 95.5605C23.81 99.3805 23.05 110.851 22.29 112.371C20.92 115.111 31.46 112.371 31.46 112.371Z"
          fill="#CBBEB2"
        />
        <path
          d="M61.1501 27.4099C57.8301 26.8199 61.6501 35.0399 59.9301 33.7799C58.2101 32.5199 53.4701 32.6399 51.4701 34.5099C49.4701 36.3799 48.3301 31.5099 45.9401 32.5099C43.5501 33.5099 39.5301 34.5699 38.5001 31.3699C37.4701 28.1699 36.5001 26.5899 34.8601 25.8799C33.2201 25.1699 37.9501 23.2199 38.6101 20.7199C39.2701 18.2199 39.6101 11.6499 41.3801 13.1699C43.1501 14.6899 42.9001 11.4299 44.4701 10.5099C46.0401 9.5899 46.3701 14.5099 48.7601 13.5099C51.1501 12.5099 51.6601 6.57987 53.0601 9.85987C54.4601 13.1399 56.3101 16.2799 57.0601 14.5899C57.8101 12.8999 60.4401 16.1899 60.6201 18.9499C60.8001 21.7099 64.4601 27.9999 61.1501 27.4099Z"
          fill="#89B380"
        />
        <path
          d="M26.6801 37.1405C24.5201 36.3705 26.1001 41.7305 25.1001 40.7705C24.2553 40.2152 23.2903 39.8693 22.2851 39.7615C21.2799 39.6536 20.2635 39.787 19.3201 40.1505C17.7501 41.0105 17.5701 37.9905 15.8401 38.2805C14.1101 38.5705 11.2901 38.7005 10.9801 36.6705C10.6701 34.6405 10.2401 33.5905 9.19011 32.9605C8.14011 32.3305 11.5901 31.7705 12.3301 30.3605C13.0701 28.9505 14.1101 25.1105 15.1001 26.2305C16.0901 27.3505 16.3301 25.3905 17.5001 25.0305C18.6701 24.6705 18.3001 27.6205 20.0201 27.3405C21.7401 27.0605 22.8001 23.5905 23.3501 25.7105C23.9001 27.8305 24.7701 29.9305 25.4401 29.0205C26.1101 28.1105 27.5201 30.3805 27.3101 32.0205C27.1001 33.6605 28.8301 37.9105 26.6801 37.1405Z"
          fill="#89B380"
        />
      </g>
      <defs>
        <clipPath id="clip0_6995_5143">
          <rect width="62.53" height="113.59" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
