import { useAccount } from "@/hooks";

export function ReportDoneCallout() {
  const { account } = useAccount();

  const userEmail = account?.email;

  return (
    <div className="border-interfaceBorder text-textPrimary bg-interfaceWhite flex flex-col gap-1 rounded border-b border-l-4 border-r border-t px-6 py-3">
      <h2 className="text-headingmd font-semibold">
        ✅ All done! We’ll send you a copy soon
      </h2>
      <p className="text-bodymd">
        A copy of this report will be sent to <b>{userEmail}</b> after it’s been
        processed.
      </p>
    </div>
  );
}
