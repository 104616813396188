import { useEffect, useState } from "react";

import { useCouncilLocalisation } from "shared/hooks";
import { useAccount } from "@/hooks";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "shared/components";

interface ReportWarningAccordionProps {
  accordionValueProp?: string[];
  isPublic?: boolean;
}

export function ReportWarningAccordion({
  accordionValueProp = [""],
  isPublic = false,
}: ReportWarningAccordionProps) {
  const { conversation } = useAccount();
  const { getLocalisationEntry } = useCouncilLocalisation(conversation);

  const [accordionValue, setAccordionValue] = useState([""]);

  useEffect(() => {
    if (isPublic) {
      setAccordionValue(accordionValueProp);
    }
  }, [accordionValueProp, isPublic]);

  return (
    <div className="border-interactiveBorder bg-interfaceWhite flex flex-col rounded-lg border">
      <div className="px-5 py-4 md:px-10 md:py-8">
        <h2 className="text-textPrimary mb-2 text-2xl font-semibold leading-7">
          ☝️️ This report is not a final planning decision
        </h2>
        <p className="text-textPrimary text-lg leading-[1.375]">
          This report is intended as a helpful guide, but it's not a final
          planning decision. If you require written confirmation of whether a
          planning permit is required or not, this report cannot be used for
          that. You must{" "}
          <a
            className="text-textLink underline"
            href={getLocalisationEntry("councilWrittenPlanningAdviceLink")}
            rel="noopener"
            target="_blank"
            tabIndex={0}
          >
            apply for written planning advice.
          </a>
        </p>
        <p className="text-textPrimary text-lg leading-[1.375]">
          Acting without necessary permits may lead to enforcement action.{" "}
          {getLocalisationEntry("councilName")} and myLot will not be
          responsible for any loss arising from use of or reliance on this
          report.
        </p>
      </div>
      <Accordion
        type="multiple"
        value={accordionValue}
        onValueChange={setAccordionValue}
      >
        <AccordionItem value="item">
          <AccordionTrigger className="border-interactiveBorder flex cursor-pointer flex-row justify-between border-t px-6 py-4 hover:no-underline">
            <label className="text-textPrimary cursor-pointer text-start text-lg font-semibold leading-[22px]">
              What this report doesn’t cover
            </label>
          </AccordionTrigger>
          <AccordionContent className="text-textPrimary px-6 text-xs leading-[14px]">
            <p>
              The permissibility report is generated using your responses and
              the entered address’ zone, overlays and lot size retrieved from
              VicPlan. It then assesses the proposal against planning scheme
              provisions that have been deemed relevant based on these inputs.
            </p>
            <p className="mb-2">
              The following factors aren’t considered and should be kept in mind
              when reading the report.
            </p>
            <ol className="mb-2	ml-0.5 list-inside list-decimal font-semibold">
              <li>
                <p className="inline">VicSmart eligibility</p>
                <p className="ml-3 font-normal">
                  VicSmart is a fast-tracked application process for simple
                  proposals like front fences and tree removal. Check the{" "}
                  <a
                    href="https://www.planning.vic.gov.au/guides-and-resources/guides/all-guides/vicsmart-permits"
                    className="text-textLink hover:text-textLinkHover underline"
                    rel="noopener"
                    target="_blank"
                    tabIndex={0}
                  >
                    VicSmart permit guide
                  </a>{" "}
                  for more information.
                </p>
              </li>
              <li>
                <p className="inline">
                  Provisions relating to disaster recovery
                </p>
                <p className="ml-3 font-normal">
                  If you’re re-establishing a business or rebuilding in response
                  to an emergency such as a bushfire, please{" "}
                  <a
                    href={getLocalisationEntry("councilContactLink")}
                    className="text-textLink hover:text-textLinkHover underline"
                    rel="noopener"
                    target="_blank"
                    tabIndex={0}
                  >
                    contact Council
                  </a>
                  .
                </p>
              </li>
              <li>
                <p className="inline">
                  Title conditions such as restrictive covenants and Section 173
                  agreements
                </p>
                <p className="ml-3 font-normal">
                  Registered restrictive covenants are recorded on the
                  certificate of title for the land. To determine whether your
                  property is affected by a restrictive covenant, you can
                  contact{" "}
                  <a
                    href="https://www.landata.online/"
                    className="text-textLink hover:text-textLinkHover underline"
                    rel="noopener"
                    target="_blank"
                    tabIndex={0}
                  >
                    Landata.online
                  </a>
                  .
                </p>
              </li>
              <li>
                <p className="inline">Past permits and existing use rights</p>
                <p className="ml-3 font-normal">
                  Additional conditions can be documented on your title or on a
                  property’s past planning permits. It is up to the lot’s owner
                  to prove that existing use rights apply.
                </p>
              </li>
              <li>
                <p className="inline">
                  Mapped layers that aren’t zones or overlays
                </p>
                <p className="ml-3 font-normal">
                  Planning provisions triggered by mapping data that can’t be
                  retrieved from VicPlan such as bushfire prone areas are not
                  assessed by this service. Overlays sometimes only apply to
                  part of a lot, which is also not accounted for by this
                  service.{" "}
                  <a
                    href="https://mapshare.vic.gov.au/vicplan/"
                    className="text-textLink hover:text-textLinkHover underline"
                    rel="noopener"
                    target="_blank"
                    tabIndex={0}
                  >
                    Find your address on VicPlan
                  </a>{" "}
                  and download a Planning Property Report to check its mapping
                  data in more detail.
                </p>
              </li>
              <li>
                <p className="inline">Funding and exemptions programs</p>
                <p className="ml-3 font-normal">
                  The report does not consider planning scheme provisions
                  relating to exemptions and concessions for certain types of
                  development, such as large residential developments and
                  affordable housing.
                </p>
              </li>
              <li>
                <p className="inline">Proposals by government agencies</p>
                <p className="ml-3 font-normal">
                  The report does not consider planning scheme provisions
                  relating to proposals submitted by government agencies and
                  related entities.
                </p>
              </li>
              <li>
                <p className="inline">
                  Planning provisions relating to referral, advertising and
                  notification requirements
                </p>
                <p className="ml-3 font-normal">
                  The report doesn’t assess the need for referrals, advertising
                  or notification. During assessment, the designated planning
                  officer will assess if your proposal needs to be referred, as
                  well as any advertising and notification requirements.
                </p>
              </li>
            </ol>
            <p className="mb-2">
              This list is not exhaustive. Remember that this report is intended
              as a helpful guide. It is not a final planning decision. Always
              seek verification from a council planner before proceeding.
            </p>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
}
