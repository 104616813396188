import { useMemo } from "react";
import { useAtomValue } from "jotai";

import { MessageType } from "shared/types";
import { sortByDate } from "@/lib/utils";
import { messagesVar } from "@/variables/globalVar";

export function useAmendedUserMessages() {
  const messages = useAtomValue(messagesVar);

  return useMemo(() => {
    return messages
      .filter((message) => message.message_type === MessageType.AMENDMENT)
      .sort(sortByDate);
  }, [messages]);
}
