import { type ReactElement } from "react";

import { useGetObjectsNearAddress } from "shared/apiHooks";
import { titleCase } from "shared/lib";

import { useAccount } from "@/hooks";

import { MapComponent } from "shared/components";

export interface LotPanelProps {
  showDisclaimer?: boolean;
}

export function LotPanel({ showDisclaimer }: LotPanelProps) {
  const { conversation } = useAccount();

  const { data: nearObjectsResponse } = useGetObjectsNearAddress({
    path: {
      address_id: conversation?.address_data?.id || "",
    },
  });

  const getOverlays = () => {
    const el: Array<ReactElement> = [];

    const overlays = conversation?.address_data?.overlays || [];

    if (overlays.length > 0) {
      overlays.forEach((overlay, index) => {
        el.push(
          <li
            key={index + "lot-overlay"}
            className="text-textPrimary list-none text-lg leading-[22px]"
          >
            {titleCase(overlay.description || "")}{" "}
            {"(Schedule " + overlay.schedule + ")"}
          </li>,
        );
      });
    } else {
      el.push(
        <li
          key="overlayNone"
          className="text-textPrimary text-lg leading-[22px]"
        >
          🌱 There are no overlays associated with this address.
        </li>,
      );
    }

    return el;
  };

  return (
    <div className="flex w-full flex-col justify-start align-top">
      <h1 className="text-textPrimary mb-3 text-start text-[28px] font-semibold leading-8">
        {titleCase(conversation?.address_data?.unstructured_address || "")}
      </h1>

      <MapComponent
        latitude={conversation?.address_data?.gps_latitude ?? undefined}
        longitude={conversation?.address_data?.gps_longitude ?? undefined}
        addressBoundary={conversation?.address_data?.boundary ?? undefined}
        nearObjects={nearObjectsResponse?.data.near_objects ?? undefined}
        className="mb-8 h-[200px] min-h-[200px] max-h-[200px] w-full rounded bg-[#E3E2E1] md:mb-10"
      />

      <div className="border-interactiveBorder bg-interfaceWhite flex flex-col rounded border">
        <div className="border-interactiveBorder border-b px-5 py-4 md:px-10 md:py-6">
          <h2 className="text-textPrimary mb-2 text-2xl font-semibold leading-7">
            Lot size
          </h2>
          <span className="text-textPrimary text-lg leading-[22px]">
            {conversation?.address_data?.lot_size_sqm
              ? `${conversation.address_data.lot_size_sqm}m`
              : "N/A"}
          </span>
          <span className="text-textPrimary align-text-top text-[8px]">2</span>
        </div>
        <div className="border-interactiveBorder border-b px-5 py-4 md:px-10 md:py-6">
          <h2 className="text-textPrimary text-2xl font-semibold leading-7">
            Zone
          </h2>
          <p className="text-textSubdued mb-3 text-base leading-5">
            Such as residential, farming, industrial
          </p>
          <p className="text-textPrimary text-lg leading-[22px]">
            {titleCase(
                conversation?.address_data?.zones[0]?.description || "",
              ) +
              " (Schedule " +
              conversation?.address_data?.zones[0]?.schedule +
              ")"}
          </p>
        </div>
        <div className="border-interactiveBorder px-5 py-4 md:px-10 md:py-6">
          <h2 className="text-textPrimary text-2xl font-semibold leading-7">
            Overlays
          </h2>
          <p className="text-textSubdued mb-3 text-base leading-5">
            Such as heritage, erosion, bushfire management
          </p>
          <div className="text-textPrimary mb-4 text-lg leading-[22px]">
            {getOverlays()}
          </div>
          <p className="text-textSubdued text-xs leading-[0.875]">
            This service treats overlays as if they apply to your entire lot.
            Sometimes, an overlay may only cover part of your lot and will not
            be applicable to your proposal. To check overlays in more detail,{" "}
            <a
              className="text-textLink cursor-pointer underline"
              href="https://mapshare.vic.gov.au/vicplan/"
              rel="noopener"
              target="_blank"
              tabIndex={0}
            >
              find your address on VicPlan
            </a>{" "}
            and create a Property Planning Report.
          </p>
        </div>
      </div>

      {showDisclaimer ? (
        <>
          <div className="border-interactiveBorder my-8 border-b md:my-10" />

          <div className="border-interactiveBorder bg-interfaceWhite rounded-lg border p-5 md:px-10 md:py-8">
            <h2 className="text-textPrimary mb-2 text-2xl font-semibold leading-7">
              ☝️ You’ll need to check for site-specific controls
            </h2>
            <p className="text-textPrimary text-lg leading-[22px]">
              This service doesn’t assess conditions, exemptions and other rules
              that are only documented on your title or past planning permits.
              However, they may still influence what you can and can’t do on a
              lot.
            </p>
            <ul className="text-textPrimary list-disc text-lg leading-[22px]">
              <li className="ml-5 mt-1 list-item">
                Existing use rights apply to older lots that are used in a way
                that is no longer permitted by the planning scheme. It is up to
                the lot’s owner to prove that existing use rights apply.
              </li>
              <li className="ml-5 list-item">
                Land encumbrances like covenants and easements can be found by
                <a
                  className="text-textLink hover:text-textLinkHover cursor-pointer underline"
                  href="https://www.land.vic.gov.au/land-registration/for-individuals/property-and-land-titles-information"
                  rel="noopener"
                  tabIndex={0}
                  target="_blank"
                >
                  {" "}
                  applying for a Certificate of Title
                </a>
                .
              </li>
            </ul>
          </div>
        </>
      ) : null}
    </div>
  );
}
