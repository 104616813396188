import { useEffect, useMemo } from "react";

import { MessageType } from "shared/types";
import { type Message } from "shared/apiClient";

import {
  MultiSelectStep,
  TextMessageStep,
  TreeDistanceStep,
  TreeSizeStep,
} from "./widgets";
import { SystemErrorDialog } from "./SystemErrorDialog";

export interface WizardStepProps {
  messages: Array<Message>;
  message?: Message;
}

export function RenderWizardStep({ messages, message }: WizardStepProps) {
  const isLatestMessage = useMemo(() => {
    return messages[messages.length - 1]?.id === message?.id;
  }, [messages, message]);

  useEffect(() => {
    console.log("<RenderWizardStep> message", message);
  }, [message]);

  switch (message?.message_type) {
    case MessageType.STANDARD:
    case MessageType.AMENDMENT:
    case MessageType.OVERWRITE:
      return (
        <TextMessageStep
          key={message.id}
          message={message}
          showLoading={!isLatestMessage}
        />
      );
    case MessageType.CHOICES_QUESTION:
      return (
        <MultiSelectStep
          key={message.id}
          message={message}
          showLoading={!isLatestMessage}
        />
      );
    case MessageType.TREE_DIAMETER_QUESTION:
      return <TreeSizeStep message={message} showLoading={!isLatestMessage} />;
    case MessageType.TREE_DISTANCE_QUESTION:
      return (
        <TreeDistanceStep message={message} showLoading={!isLatestMessage} />
      );
    case MessageType.SYSTEM_ERROR:
      return (
        <div className="h-full w-full p-6">
          <SystemErrorDialog />
        </div>
      );
    default:
      return null;
  }
}
