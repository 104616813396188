import { useState, useRef, FormEvent, useEffect } from "react";
import { useSetAtom } from "jotai";
import { useQueryClient } from "@tanstack/react-query";

import {
  AuthFlow,
  authFlowSelectedVar,
  isAuthenticatedVar,
} from "@/variables/globalVar";
import {
  getAccountQueryKey,
  useGetAuthSocialList,
  useLogin,
  useSignUp,
} from "shared/apiHooks";
import { type SocialApp } from "shared/apiClient";

import { useCouncilLocalisation } from "shared/hooks";
import { useAccount } from "@/hooks";

import { IconSpinner } from "@/components/icons";
import {
  Button,
  Checkbox,
  Input,
  Label,
  Separator,
  SocialLoginButtons,
} from "shared/components";

export function SignUp() {
  const queryClient = useQueryClient();

  const setAuthFlowSelectedVar = useSetAtom(authFlowSelectedVar);
  const setAuthenticatedVar = useSetAtom(isAuthenticatedVar);

  const { conversation } = useAccount();
  const { getLocalisationEntry } = useCouncilLocalisation(conversation);

  const [showEmailConfirm, setShowEmailConfirm] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");

  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);

  const [socialApps, setSocialApps] = useState<SocialApp[]>([]);

  const [consentChecked, setConsentChecked] = useState(false);

  const {
    data: authSocialListResponse,
    error: authSocialListError,
    isLoading: isLoadingAuthSocialList,
  } = useGetAuthSocialList();

  useEffect(() => {
    if (authSocialListError) {
      console.error(
        "<SignUp> useGetAuthSocialList() onError()",
        authSocialListError,
      );
      setErrorMsg("Failed to load social login options.");
    }
  }, [authSocialListError]);

  useEffect(() => {
    if (authSocialListResponse) {
      const results = authSocialListResponse.data?.results || [];
      const transformedResults = results.map((app) => ({
        ...app,
        login_url: app.login_url ?? "", // Fallback to an empty string if login_url is undefined
      }));
      setSocialApps(transformedResults);
    }
  }, [authSocialListResponse]);

  const { isPending: isLoginPending, mutate: mutateLogin } = useLogin({
    onError: (error) => {
      console.error("<SignUp> useLogin() onError()", error);
      const response = error.response;
      if (response?.data && Array.isArray(response.data["non_field_errors"])) {
        setErrorMsg(response.data["non_field_errors"][0]);
      } else {
        setErrorMsg(
          "There was an unexpected error logging you in. Please refresh your browser and try again, or contact support for assistance.",
        );
      }
    },
    onSuccess: (response) => {
      console.log("<SignUp> useLogin() onSuccess()", response);
      if (response.data) {
        queryClient.setQueryData(getAccountQueryKey(), response);
        setAuthenticatedVar(true);
      }
    },
  });

  const { isPending: isSignupPending, mutate: mutateSignup } = useSignUp({
    onError: (error) => {
      console.error("<SignUp> useSignUp() onError()", error);
      const response = error.response;
      if (response?.data && Array.isArray(response.data["non_field_errors"])) {
        setErrorMsg(response.data["non_field_errors"][0]);
      } else {
        setErrorMsg(
          "There was an unexpected error signing you up. Please refresh your browser and try again, or contact support for assistance.",
        );
      }
    },
    onSuccess: (response) => {
      console.log("<SignUp> useSignUp() onSuccess()", response);

      setErrorMsg("");
      setShowEmailConfirm(true);

      const email = emailRef.current?.value || "";
      const password = passwordRef.current?.value || "";

      mutateLogin({ body: { email, password } });
    },
  });

  const handleClickConsentCheckbox = (checked: boolean) => {
    setConsentChecked(checked);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const email = emailRef.current?.value || "";
    const password = passwordRef.current?.value || "";

    setErrorMsg("");
    mutateSignup({
      body: {
        email,
        password1: password,
        password2: password,
        contact_consent: consentChecked,
        tc_read: true,
      },
    });
  };

  return (
    <div className="flex flex-col gap-6 p-6">
      <h1 className="text-[32px] font-semibold leading-10 tracking-tight">
        Sign up
      </h1>

      <p
        className="text-textPrimary leading-[22px]"
        style={{ fontSize: "16px" }}
      >
        Your email address will be used to send a copy of the result of your
        enquiry to you and the council.
      </p>

      <SocialLoginButtons
        socialApps={socialApps}
        disabled={isLoginPending || isSignupPending}
      />

      <div className="flex items-center gap-2">
        <Separator className="w-auto grow" />
        <span className="text-bodysm text-textSubdued">
          or sign up with email
        </span>
        <Separator className="w-auto grow" />
      </div>

      <form onSubmit={handleSubmit}>
        <div className="grid gap-3">
          <div className="grid gap-1">
            <Label className="sr-only" htmlFor="email">
              Email
            </Label>
            <Input
              className="border-interactiveBorder placeholder:text-textSubdued rounded-lg p-4 text-base leading-5"
              id="email"
              placeholder="Email address"
              ref={emailRef}
              type="email"
              autoCapitalize="none"
              autoComplete="email"
              autoCorrect="off"
              disabled={isLoginPending || isSignupPending}
              required
            />
          </div>
          <div className="grid gap-1">
            <Label className="sr-only" htmlFor="password">
              Password
            </Label>
            <Input
              className="border-interactiveBorder placeholder:text-textSubdued rounded-lg p-4 text-base leading-5"
              id="password"
              type="password"
              ref={passwordRef}
              placeholder="Password"
              autoCapitalize="none"
              autoComplete="password"
              autoCorrect="off"
              disabled={isLoginPending || isSignupPending}
              title="Password must be atleast 8 characters"
              pattern={"^.{8,}$"}
              required
            />
          </div>
          <div className="grid gap-1">
            <Label className="sr-only" htmlFor="confirmPassword">
              Confirm Password
            </Label>
            <Input
              className="border-interactiveBorder placeholder:text-textSubdued rounded-lg p-4 text-base leading-5"
              id="confirmPassword"
              type="password"
              ref={confirmPasswordRef}
              placeholder="Confirm password"
              autoCapitalize="none"
              autoComplete="password"
              autoCorrect="off"
              disabled={isLoginPending || isSignupPending}
              required
              onChange={(_event) => {
                if (
                  confirmPasswordRef.current?.value ===
                  passwordRef.current?.value
                ) {
                  confirmPasswordRef.current?.setCustomValidity("");
                } else {
                  confirmPasswordRef.current?.setCustomValidity(
                    "Passwords must match",
                  );
                }
              }}
            />
          </div>

          <div className="inline-flex">
            <div>
              <Checkbox
                role="checkbox"
                className="border-interactiveBorder rounded"
                id="feedback-consent"
                name="feedback-consent"
                onCheckedChange={handleClickConsentCheckbox}
              />
            </div>
            <div className="ml-2">
              <label
                className=" text-textPrimary text-base leading-5"
                htmlFor="feedback-consent"
              >
                I give my consent to be contacted by email for feedback about
                this service{" "}
                <span className="text-textSubdued">(optional)</span>
              </label>
            </div>
          </div>

          <p className="text-textSubdued mt-2 text-xs leading-4">
            You can find more information about how{" "}
            {getLocalisationEntry("councilName")} collects, uses, stores or
            discloses personal information in our{" "}
            <a
              href={getLocalisationEntry(
                "councilPrivacyInformationLink",
              )}
              className="text-textLink"
              target="_blank"
              rel="noopener noreferrer"
              tabIndex={0}
            >
              Privacy & Health Information Policy
            </a>
            .
          </p>

          <Button
            className="bg-actionDefault text-textWhite mt-1 rounded px-4 py-2 text-lg font-semibold leading-[22px]"
            disabled={isLoginPending || isSignupPending}
          >
            Sign up
            {isLoginPending || isSignupPending ? (
              <IconSpinner className="mr-2 h-4 w-4 animate-spin" />
            ) : null}
          </Button>
          <p className="flex flex-row justify-center">
            <span className="pr-1 text-base leading-5">
              Already have an account?
            </span>
            <button
              disabled={isLoginPending || isSignupPending}
              onClick={(event) => {
                event.preventDefault();
                setAuthFlowSelectedVar(AuthFlow.Login);
              }}
              className="text-textLink text-base leading-5 underline"
            >
              Log in
            </button>
          </p>
          <div
            hidden={!showEmailConfirm}
            className="mt-6 rounded border border-black bg-blue-300 p-3"
          >
            Your account has been created. Please login to continue.
          </div>
          <div
            hidden={errorMsg === ""}
            className="border-destructiveBorder bg-destructiveBgDefault text-destructiveText mt-6 rounded border p-3"
          >
            {errorMsg}
          </div>
        </div>
      </form>
    </div>
  );
}
