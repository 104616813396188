import { useSearchParams } from "react-router-dom";

import { type Application } from "shared/apiClient";

import { useCouncilLocalisation } from "shared/hooks";
import { useAccount } from "@/hooks";

import {
  BaysideCityCouncilLogo,
  BoroondaraCityCouncilLogo,
} from "shared/components";
import { MyLotLogo, YarraRangesIcon } from "@/components/icons";

const isProd = ["prod"].includes(import.meta.env.VITE_REACT_ENV);

const logoMap = {
  BAY: <BaysideCityCouncilLogo className="h-full w-full" />,
  // BOR: <BoroondaraCityCouncilLogo className="h-full w-full" />,
  BOR: <YarraRangesIcon />,
  YRC: <YarraRangesIcon />,
};

function useGetCouncilCode(application: Application | undefined) {
  const [searchParams] = useSearchParams();

  const councilCode = (
    searchParams.get("cc") ??
    application?.council?.code ??
    ""
  ).toUpperCase();

  if (isProd && !councilCode) {
    return "YRC";
  }

  return councilCode;
}

export function LocalisedLogo() {
  const { application, conversation } = useAccount();
  const { getLocalisationEntry } = useCouncilLocalisation(conversation);

  const councilCode = useGetCouncilCode(application);

  const logo = councilCode in logoMap ? logoMap[councilCode] : <MyLotLogo />;

  return (
    <a
      className="h-full w-full"
      href={getLocalisationEntry("councilPlanningWebsite")}
      tabIndex={0}
    >
      {logo}
    </a>
  );
}
