import { useEffect } from "react";
import { useAtom } from "jotai";
import { DraftingCompass, Home, ListChecks, MessageSquare } from "lucide-react";

import {
  Tabs,
  tabNotificationsVar,
  tabSelectedVar,
} from "@/variables/globalVar";

import { TabItem } from "./TabItem";

export function MobileTabs() {
  const [tabSelected, setTabSelectedVar] = useAtom(tabSelectedVar);
  const [tabNotifications, setTabNotificationsVar] =
    useAtom(tabNotificationsVar);

  useEffect(() => {
    switch (tabSelected) {
      case Tabs.Chat:
        //tabNotifs({chat:false, lot:tabNotifications.lot, proposal: tabNotifications.proposal, permissibility: tabNotifications.permissibility});
        break;
      case Tabs.Lot:
        setTabNotificationsVar({
          chat: tabNotifications.chat,
          lot: false,
          proposal: tabNotifications.proposal,
          permissibility: tabNotifications.permissibility,
        });
        break;
      case Tabs.Proposal:
        setTabNotificationsVar({
          chat: tabNotifications.chat,
          lot: tabNotifications.lot,
          proposal: false,
          permissibility: tabNotifications.permissibility,
        });
        break;
      case Tabs.Permissibility:
        setTabNotificationsVar({
          chat: tabNotifications.chat,
          lot: tabNotifications.lot,
          proposal: tabNotifications.proposal,
          permissibility: false,
        });
        break;

      default:
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabSelected]);

  return (
    <div className="border-interactiveBorder flex w-full flex-row border-t">
      <TabItem
        icon={MessageSquare}
        label="Chat"
        selected={tabSelected === "chat"}
        onSelect={() => setTabSelectedVar(Tabs.Chat)}
      />
      <TabItem
        icon={Home}
        label="Lot"
        selected={tabSelected === "lot"}
        onSelect={() => setTabSelectedVar(Tabs.Lot)}
        showUpdateIcon={tabNotifications.lot}
      />
      <TabItem
        icon={DraftingCompass}
        label="Proposal"
        selected={tabSelected === "proposal"}
        onSelect={() => setTabSelectedVar(Tabs.Proposal)}
        showUpdateIcon={tabNotifications.proposal}
      />
      <TabItem
        icon={ListChecks}
        label="Report"
        selected={tabSelected === "permissibility"}
        onSelect={() => setTabSelectedVar(Tabs.Permissibility)}
        showUpdateIcon={tabNotifications.permissibility}
      />
    </div>
  );
}
