import { PromptFormLoadingMessage } from "@/components/chat/PromptFormLoadingMessage";

export function LoadingOverlay() {
  return (
    <div className="absolute inset-0 z-50 flex items-center justify-center">
      <div className="absolute inset-0 bg-white opacity-75" />

      <div className="border-interfaceBorder flex rounded bg-white p-3 opacity-70">
        <PromptFormLoadingMessage />
      </div>
    </div>
  );
}
