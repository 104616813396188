import { type Message } from "shared/apiClient";
import { type Noticeboard } from "shared/lib";
import {
  getReportLoadingProgressIndicatorItems,
  reportLoadingProgressIndicatorItemsMap,
  type ProgressIndicatorItemMap,
} from "./lib";

import { AmendingReportLoader } from "./AmendingReportLoader";

export interface AmendingReportLoaderContainer {
  amendedUserMessages: Array<Message>;
  className?: string;
  latestUserMessage?: Message;
  noticeboardData: Noticeboard;
  progressIndicatorItemMap?: ProgressIndicatorItemMap;
}

export function AmendingReportLoaderContainer({
  amendedUserMessages,
  className,
  latestUserMessage,
  noticeboardData,
  progressIndicatorItemMap = reportLoadingProgressIndicatorItemsMap,
}: AmendingReportLoaderContainer) {
  return (
    <AmendingReportLoader
      className={className}
      amendedUserMessages={amendedUserMessages}
      items={getReportLoadingProgressIndicatorItems(
        noticeboardData,
        parseInt(latestUserMessage?.message_number || "-999"),
        progressIndicatorItemMap,
      )}
    />
  );
}
