import { useEffect, useState } from "react";

import { type Message } from "shared/apiClient";
import { MessageType, AccountType } from "shared/types";

const TEXT_ANIMATION_SPEED = 20;

export interface TextMessageWidgetProps {
  message: Message;
  animated?: boolean;
  hideTime?: boolean;
  isPublic?: boolean;
}

export function TextMessageWidget({
  message,
  animated = false,
  hideTime = false,
  isPublic = false,
}: TextMessageWidgetProps) {
  const [content, setContent] = useState<string>("");
  const [currentIndex, setCurrentIndex] = useState(0);

  const [lastMessageStreamed, setLastMessageStreamed] = useState<Message>();

  useEffect(() => {
    if (!isPublic) {
      const unparsedMessage = localStorage.getItem("lastMessageStreamed");
      if (unparsedMessage != null && unparsedMessage !== "undefined") {
        const parsedItems = JSON.parse(unparsedMessage);
        if (parsedItems) {
          setLastMessageStreamed(parsedItems);
        }
      }
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (animated && message.text !== lastMessageStreamed?.text) {
      if (currentIndex === 0) {
        setContent("");
      }
      if (currentIndex < (message.text || "").length) {
        const timeout = setTimeout(() => {
          setContent((prevText) => prevText + (message.text || "")[currentIndex]);
          setCurrentIndex((prevIndex) => prevIndex + 1);
        }, TEXT_ANIMATION_SPEED);

        return () => {
          clearTimeout(timeout);
        };
      }
      localStorage.setItem("lastMessageStreamed", JSON.stringify(message.text));
    } else {
      setContent(message.text || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex, message.text, animated]);

  const getColourStyles = () => {
    if (
      message.sender_account_type !== AccountType.ASSISTANT &&
      message.message_type === MessageType.AMENDMENT
    ) {
      return "bg-actionBgDefault text-textPrimary";
    } else if (message.sender_account_type !== AccountType.ASSISTANT) {
      return "bg-actionDefault text-textWhite";
    } else {
      return "bg-[#F5F5F5] text-textPrimary";
    }
  };

  return (
    <div
      className={`flex w-full flex-col ${message.sender_account_type !== AccountType.ASSISTANT ? "pl-5 md:pl-20" : "pr-5 md:pr-20"}`}
    >
      <div
        hidden={hideTime}
        className={`mb-0.5 flex w-full flex-row items-center ${message.sender_account_type !== AccountType.ASSISTANT ? "justify-end" : "justify-start"}`}
      >
        <time className="text-textSubdued ml-1 text-xs leading-[18px]">
          {new Intl.DateTimeFormat("en-US", {
            timeStyle: "short",
          }).format(new Date(message.created_at || ""))}
        </time>
      </div>

      <p
        className={`rounded-lg px-5 py-2 text-lg leading-[22px] ${
          message.sender_account_type !== AccountType.ASSISTANT
            ? "self-end"
            : "self-start"
        } ${getColourStyles()}`}
      >
        {message.message_type === MessageType.AMENDMENT ? "Adjustment: " : null}
        {content}
      </p>
    </div>
  );
}
